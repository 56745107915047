import actions from './actions';

const { SET_VENUES, SET_VENUE_PROFILE, BEGIN, ERROR } = actions;

const initialState = {
    venues: [],
    venueProfile: null,
    loading: false,
    error: null,
    total: 0,
};

const venuesReducer = (state = initialState, action) => {
    const { type, data, err, total } = action;
    switch (type) {
        case BEGIN:
            return {
                ...state,
                loading: true,
            };
        case ERROR:
            return {
                ...state,
                loading: false,
                error: err,
            };
        case SET_VENUES:
            return {
                ...state,
                venues: data,
                total,
                loading: false,
            };
        case SET_VENUE_PROFILE:
            return {
                ...state,
                loading: false,
                venueProfile: data,
            };
        default:
            return state;
    }
};

export default venuesReducer;
