import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Url } from "../../../utils/apiUrl";
import { postApiWithoutAuth } from "../../../utils/api";
import { setToken } from "../../../utils/localStorage";
import { InputField, NLBtn } from "../../commonComponents";
import logo from "../../../assets/images/LOGO.png";
import welcomeText from "../../../assets/images/WelcomeText.png";
import mailIcon from "../../../assets/images/EMAIL.svg";
import lockIcon from "../../../assets/images/LOCKPASSWORD.svg";
import "./Login.scss";
import { useDispatch } from 'react-redux';
import { addUserPayload } from '../../../redux/auth/actionCreator';

const Login = () => {
    const dispatch = useDispatch();
    const boolVariable = true;
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [userData, setUserData] = useState({
        email: '',
        password: '',
        app: 'admin_web'
    });

    const onFinish = async () => {
        setisLoading(true);
        try {
            const response = await postApiWithoutAuth(Url.loginUrl, userData);
            console.log('[response]', response);
            if (
                response.success === true &&
                (response.data.userType === 'masterAdmin' || response.data.userType === 'masterStaff')
            ) {
                setToken(response.data.accessToken);

                message.success('Login successfully');
                dispatch(addUserPayload());
                navigate('/dashboard');
            } else {
                message.error('Invalid Login Credentials');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setisLoading(false);
        }
    };

    const onChangeValue = (event) => {
        const { value, name } = event.target;
        setUserData({ ...userData, [name]: value });
    };

    return (
        <>
            <div className="loginContainer">
                <div className="logoContainer">
                    <div className="siteLogo">
                        <img src={logo} width={'40%'} />
                    </div>
                </div>
                <div className="formMainContainer">
                    <div className="loginFormContainer">
                        <Form
                            name="normal_login"
                            className="login-form"
                            onFinish={onFinish}
                            style={{ maxWidth: '72%' }}
                        >
                            <div className="welcomTextContainer">
                                <img src={welcomeText} />
                            </div>
                            <div className="adjustFormInputs">
                                <Form.Item
                                    name="email"
                                    className="inputStyle "
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Your Email!',
                                        },
                                        {
                                            pattern:
                                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'Please Enter Valid Email',
                                        },
                                    ]}
                                >
                                    <InputField
                                        placeholder="Please Enter Your Email"
                                        type="email"
                                        onChange={onChangeValue}
                                        value={userData.email}
                                        name={'email'}
                                        prefix={<img src={mailIcon} />}
                                        label={'Email *'}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Password Required',
                                        },
                                        {
                                            pattern:
                                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/,
                                            message: 'Please Enter Valid Password',
                                        },
                                    ]}
                                    className="inputStyle "

                                >
                                    <InputField
                                        placeholder="Please Enter Your Password"
                                        type="password"
                                        onChange={onChangeValue}
                                        className="ant-input-password"
                                        value={userData.password}
                                        name={'password'}
                                        prefix={<img src={lockIcon} />}
                                        label={'Password *'}
                                    />
                                </Form.Item>
                            </div>
                            <div className="forgotPasswordText">
                                <Link >Forgot Password?</Link>
                            </div>
                            <NLBtn
                                htmlType="submit"
                                isLoading={isLoading}
                                name={'Login'}
                            />
                        </Form>

                    </div>
                </div>
            </div >
        </>
    );
};

export default Login;
