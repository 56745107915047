import { Col, Pagination, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import './tableStyle.scss';

export default function TablePagination({ total, onChange, isLoading, resetPaginationIndex, setResetPagination }) {
    const [page, setPage] = useState(1);

    const [pageSize, setPageSize] = useState(10);

    const resetPagination = (resetPaginationIndex) => {
        if (resetPaginationIndex === 1) {
            setPage(1);
            setResetPagination(null);
        }
    };
    useEffect(() => {
        resetPagination(resetPaginationIndex);

        return () => {
        }
    }, [resetPaginationIndex])



    return (
        !isLoading && <Row className="table-pagination-wrapper">
            <Col>
                {!isLoading && <p>
                    {page === 1 ? 1 : (page - 1) * pageSize + 1} to{' '}
                    {page * pageSize > total ? total : page * pageSize} of {total} entries

                </p>
                }
            </Col>

            <Col className="table-paginate custom-arrow">
                <Pagination

                    total={total}
                    defaultPageSize={pageSize}
                    defaultCurrent={1}
                    current={page}
                    onChange={(_page, _pageSize) => {
                        setPage(_page);
                        setPageSize(_pageSize);
                        onChange(_page, _pageSize);
                    }}
                />
            </Col>
        </Row>
    );
}
