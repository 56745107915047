const actions = {
    ERROR: 'ERROR',
    BEGIN: 'BEGIN',
    SET_USER: 'SET_USER',
    USER_LOGOUT: 'USER_LOGOUT',

    begin: () => {
        return {
            type: actions.BEGIN,
        };
    },

    error: (err) => {
        return {
            type: actions.ERROR,
            err,
        };
    },

    setUser: (data) => {
        return {
            type: actions.SET_USER,
            data,
        };
    },

    logout: () => {
        return {
            type: actions.USER_LOGOUT,
            data: null,
        };
    },
};

export default actions;
