import React from 'react';
import './TabBar.scss';

export default function TabBar({ activePage, setActivePage, pagesMetaData }) {
    function changeactivePage(value) {
        setActivePage(value);
    }

    return (
        <div className="dashboard-navigation-header">
            <ul className="dashboard-navigation-header-menu">
                {Array.isArray(pagesMetaData) &&
                    pagesMetaData.map((meteData, index) => {
                        return (
                            <div
                                onClick={() => changeactivePage(meteData.value)}
                                className="dashboard-navigation-header-menu-tab"
                                key={index}
                            >
                                <div
                                    className="dashboard-navigation-active-bar"
                                    style={{
                                        visibility:
                                            activePage == meteData.value
                                                ? 'visible'
                                                : 'hidden',
                                    }}
                                />
                                <li
                                    className="dashboard-navigation-active-bar-link"
                                    style={{
                                        color:
                                            activePage == meteData.value
                                                ? '#AD70E0'
                                                : '#94979e',
                                    }}
                                >
                                    {meteData.label}
                                </li>
                            </div>
                        );
                    })}
            </ul>
        </div>
    );
}
