import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  Institutions,
  Club,
  InstituteDetail,
  ClubDetail,
  LogoGallery,
  InformationFiles,
  IDcustomisation,
  TicketCustomisation,
  ClubLogoGallery,
  Manager,
  ManagerDetail,
  StudentInformation,
  Analytics,
  ClubAnalytics,
  InstituteAnalytics,
  AnalyticsUsers,
  ApplicationAnalytics,
} from "./components/mainComponents";
import { Login } from "./components/authComponents";
import PrivateRoute from "./route/PrivateRouting";
import PublicRoute from "./route/PublicRouting";
import "./assets/fonts/OpenSans-Regular.ttf";
import "./App.css";
import Modal from "./components/mainComponents/Modal";
import { AppStateProvider } from "./utils/context";
import Dashboard from "./pages/Dashboard/Dashboard";
import VenuesDetail from "./pages/VenueDetail/VenuesDetail";
import Venues from "./pages/Venues/Venues";
import Staff from "./pages/Staff/Staff";
import StaffCreate from './pages/StaffCreate/StaffCreate';
import StaffView from './pages/StaffView/StaffView';
import UserManagment from './pages/UserManagment/UserManagment';
import Comming from './pages/CommingSoon/Comming';

function App() {
    return (
        <AppStateProvider>
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <PublicRoute restricted={true}>
                                    <Login />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path="/studentInformation"
                            element={
                                <PublicRoute>
                                    <StudentInformation />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path="/modal"
                            element={
                                <PrivateRoute status={true}>
                                    <Modal />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/login"
                            element={
                                <PublicRoute restricted={true}>
                                    <Login />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute status={true}>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/dashboard/venues"
                            element={
                                <PrivateRoute status={true}>
                                    <Venues />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/dashboard/venues-analytics"
                            element={
                                <PrivateRoute status={true}>
                                    <Comming />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/dashboard/venues/:id"
                            element={
                                <PrivateRoute status={true}>
                                    <VenuesDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/dashboard/staff"
                            element={
                                <PrivateRoute status={true}>
                                    <Staff />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/dashboard/staff/create"
                            element={
                                <PrivateRoute status={true}>
                                    <StaffCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/dashboard/staff/view/:id"
                            element={
                                <PrivateRoute status={true}>
                                    <StaffView />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/dashboard/user-managment"
                            element={
                                <PrivateRoute status={true}>
                                    <UserManagment />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/dashboard/user-analytic"
                            element={
                                <PrivateRoute status={true}>
                                    <Comming />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/dashboard/setting"
                            element={
                                <PrivateRoute status={true}>
                                    <Comming />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/home"
                            element={
                                <PrivateRoute>
                                    <Home />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/club"
                            element={
                                <PrivateRoute status={false}>
                                    <Club />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/analytics"
                            element={
                                <PrivateRoute
                                    status={false}
                                    sidebar={'analytics'}
                                >
                                    <Analytics />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/analytics/clubAnalytics"
                            element={
                                <PrivateRoute
                                    status={false}
                                    sidebar={'analytics'}
                                >
                                    <ClubAnalytics />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/analytics/instituteAnalytics"
                            element={
                                <PrivateRoute
                                    status={false}
                                    sidebar={'analytics'}
                                >
                                    <InstituteAnalytics />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/analytics/analyticUsers"
                            element={
                                <PrivateRoute
                                    status={false}
                                    sidebar={'analytics'}
                                >
                                    <AnalyticsUsers />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/analytics/applicationAnalytics"
                            element={
                                <PrivateRoute
                                    status={false}
                                    sidebar={'analytics'}
                                >
                                    <ApplicationAnalytics />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/institute/institutiondetail/:id"
                            element={
                                <PrivateRoute
                                    status={false}
                                    sidebar={'institute'}
                                >
                                    <InstituteDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/club/clubdetail/:id"
                            element={
                                <PrivateRoute status={false} sidebar={'club'}>
                                    <ClubDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/institute/instituteLogogallery/:id"
                            element={
                                <PrivateRoute
                                    status={false}
                                    sidebar={'institute'}
                                >
                                    <LogoGallery />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/institute/informationFiles/:id"
                            element={
                                <PrivateRoute
                                    status={false}
                                    sidebar={'institute'}
                                >
                                    <InformationFiles />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/institute/idcustomisation/:id"
                            element={
                                <PrivateRoute
                                    status={false}
                                    sidebar={'institute'}
                                >
                                    <IDcustomisation />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/club/ticketcustomisation/:id"
                            element={
                                <PrivateRoute status={false} sidebar={'club'}>
                                    <TicketCustomisation />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/club/clubLogogallery/:id"
                            element={
                                <PrivateRoute status={false} sidebar={'club'}>
                                    <ClubLogoGallery />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/club/manager/:id"
                            element={
                                <PrivateRoute status={false} sidebar={'club'}>
                                    <Manager />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/club/manager/managerDetail/:id"
                            element={
                                <PrivateRoute status={false} sidebar={'club'}>
                                    <ManagerDetail />
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </div>
        </AppStateProvider>
    );
}

export default App;
