import React, { useCallback, useEffect, useState } from 'react'
import PageHeader from '../../components/page-header/PageHeader'
import TableWrapper from '../../components/table/TableWrapper';
import { Button, Col, Dropdown, Row, message } from 'antd';
import SearchInput from '../../components/search-input/SearchInput';
import FieldInput from '../../components/field-input/FieldInput';
import InputDate from '../../components/datepicker/InputDate';
import SelectInput from '../../components/select-input/SelectInput';
import { MailOutlined, FilterOutlined } from '@ant-design/icons';
import TableHeader from '../../components/table/TableHeader';
import DataTable from '../../components/table/DataTable';
import TablePagination from '../../components/table/TablePagination';
import { blockUserAccount, deleteUserAccount, getUserList } from '../../redux/userManagement/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import NLModal from '../../components/commonComponents/NLModal/NLModal';
import { emailConfirmation, sendEmailOtp } from '../../redux/auth/actionCreator';

const columns = [
    {
        title: 'Account ID',
        dataIndex: 'accountId',
        sort: true,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        sort: true,
    },
    {
        title: 'Date of Birth',
        dataIndex: 'dob',
        sort: true,
    },
    {
        title: 'Date of Registration',
        dataIndex: 'Date of Registration',
        sort: true,
    }, {
        title: 'Status',
        dataIndex: 'status',
        sort: true,
    },

    {
        title: 'Action',
        dataIndex: 'action',
    },
];

export default function UserManagment() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userList, total, isLoading, user } = useSelector(_state =>
    ({
        userList: _state.userList.users,
        total: _state.userList.total,
        isLoading: _state.userList.loading,
        user: _state.auth.user
    })
    )

    const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
    const [code, setCode] = useState(null);


    const [filter, setFilter] = useState({
        accountId: '',
        email: '',
        dateofbirth: '',
        registrationDate: '',
        status: ''
    });
    const [target, setTarget] = useState({
        id: '',
        email: '',
        status: false
    });

    const openBlockModal = () => setIsOpenBlockModal(true);
    const closeBlockModal = () => {
        setIsOpenBlockModal(false);
        setTarget({
            id: '',
            status: false
        });
    };

    const openDeleteModal = () => setIsOpenDeleteModal(true);
    const closeDeleteModal = () => {
        setIsOpenDeleteModal(false);
        setTarget({
            id: '',
            status: false
        });
    };
    const openConfirmModal = () => setIsOpenConfirmationModal(true);
    const closeConfirmModal = () => {
        setIsOpenConfirmationModal(false);
        setTarget('');
        setCode((pre) => null);
    };

    const userBlockHandler = () => {
        dispatch(blockUserAccount(target.id, target.status, (text) => {
            message.success(text);
            closeBlockModal()
        }));
    }

    const confirmationDeleteHandler = () => {
        dispatch(sendEmailOtp({
            email: user?.email,
            firstName: user?.firstName
        }))
        setIsOpenDeleteModal(false);
        setIsOpenConfirmationModal(true);
    }

    const userDeleteHandler = () => {
        dispatch(emailConfirmation({
            code: parseInt(code), email: user?.email,
        }, (data) => {
            dispatch(deleteUserAccount(target.id, (text, success) => {
                if (success) {
                    message.success(text)
                    setCode(null);
                } else {
                    setCode(null);
                    message.error(text)
                }
            }))
            setIsOpenConfirmationModal(false);
        }))
    }

    const onFilterHandler = (event) => {
        const { name, value } = event.target;
        console.log("name = ", name, " value = ", value);
        setFilter((previousFilter) => ({ ...previousFilter, [name]: value }));
    };

    const onFilterDateHandler = (date) => {
        console.log('date = ', date)
        setFilter((previousFilter) => ({ ...previousFilter, createdAt: date }));
    };

    const items = useCallback(
        (id, payload) => [
            {
                key: '3',

                label: (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="#"

                        onClick={(e) => {
                            e.preventDefault();
                            openBlockModal();
                            setTarget({ id, status: payload?.status !== "active", email: payload?.email })
                        }}
                    >
                        {payload?.status === "active" ? "Block" : "Unblock"}

                    </a>
                ),
            },
            {
                key: '4',
                label: (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="#"
                        style={{ color: 'red' }}

                        onClick={(e) => {
                            e.preventDefault();
                            openDeleteModal();
                            setTarget({ id, status: payload?.status !== "active", email: payload?.email })
                        }}
                    >
                        Delete Account
                    </a>
                ),
            },
        ],
        [],
    );


    const handleFilterButtonClick = () => {
        // console.log("filter btn clicked")
        getUsersList(1, 10);
        console.log('filter', filter, "userList ", userList)

    }

    const getUsersList = useCallback((_page, _pageSize) => {
        let filterStr = '';
        for (const key in filter) {
            if (Object.hasOwnProperty.call(filter, key)) {
                filterStr += `&${key}=${filter[key]}`;
            }
        }
        dispatch(getUserList((_page - 1) * _pageSize, _pageSize, filterStr))
    }, [filter])

    useEffect(() => {
        getUsersList(1, 10);
    }, [])

    return (
        <div>
            <PageHeader label={'User Account Management'} />
            <TableWrapper>
                <Row justify="space-between">
                    <Col span={6}>
                        <SearchInput
                            value={filter.userName}
                            name="accountId"
                            onChange={onFilterHandler}
                        />
                    </Col>
                    <Col span={6}>
                        <FieldInput
                            key={1}
                            label={'Email'}
                            name={'email'}
                            onChange={onFilterHandler}
                            labelSize={false}
                            placeholder={'Email'}
                            value={filter.address}
                            suffix={
                                <MailOutlined
                                    style={{ color: '#6C757D' }}
                                />
                            }
                        />
                    </Col>
                    <Col span={6} >
                        <InputDate
                            label={'Date of Birth'}
                            labelSize={false}
                            name={'dateofbirth'}
                            type='date'
                            value={filter.createdAt}
                            placeholder={'Choose date'}
                            onChange={onFilterDateHandler}
                        // className="custom-date-input"
                        />

                    </Col>

                    {/* <Col span={5}>
                        <SelectInput
                            labelSize={false}
                            placeholder={'Choose Duration'}
                            label={'Account Duration'}
                            value={filter.sort}
                            handleChange={(value) =>
                                onFilterHandler({
                                    target: {
                                        name: 'sort',
                                        value: value,
                                    },
                                })
                            }
                            name="sort"
                            options={[
                                {
                                    label: 'Highest to lowest',
                                    key: 1,
                                    value: 'desc',
                                },
                                {
                                    label: 'Lowest to highest',
                                    key: 2,
                                    value: 'asc',
                                },
                            ]}
                        />
                    </Col> */}
                    <Col span={4}>
                        <Button icon={<FilterOutlined />} className=
                            'filterBtn' onClick={() => handleFilterButtonClick()}>
                            Apply
                        </Button>
                    </Col>
                </Row>
                <br />
                <TableHeader
                    title={'The number of user registered with NEVERLEFT'}
                    status={total}
                />
                <DataTable
                    columns={columns}
                    loading={isLoading}
                    callback={
                        Array.isArray([]) &&
                        userList?.length > 0 &&
                        userList?.map((row, index) => (
                            <tr key={index} className="table-row">

                                <td className='table-data'>{row?.accountId ?? '--'}</td>
                                <td className='table-data'>
                                    {row?.email ?? '--'}
                                </td>
                                <td className='table-data'>{moment(row?.dateofbirth).format('YYYY/MM/DD') ?? '--'}</td>
                                <td className='table-data'>{moment(row?.registrationDate).format('YYYY/MM/DD') ?? '--'}</td>

                                <td className='table-data'>{row?.status ?? '--'}</td>
                                <td className='table-data'>
                                    <Dropdown
                                        menu={{
                                            items: items(row?._id, row),
                                        }}
                                        trigger={['click']}
                                    >
                                        <a
                                            className="ant-dropdown-link"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <img src="/assets/images/Union.svg" />
                                        </a>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))
                    }
                />
                <TablePagination
                    total={total}
                    onChange={(_page, _pageSize) => {
                        getUsersList(_page, _pageSize);
                    }}
                    isLoading={isLoading}
                />
            </TableWrapper>

            <NLModal isOpen={isOpenBlockModal} handleModel={closeBlockModal} showHeading showConfirmBUtton showCancelBUtton confirmButtonText={'Confirm'} cancelButtonText={'Cancel'} handleConfirm={userBlockHandler} heading={`Are you sure want to ${target.status ? 'ubblock' : 'block'} this user account?`} />
            <NLModal isOpen={isOpenDeleteModal} handleModel={closeDeleteModal} showHeading showConfirmBUtton showCancelBUtton confirmButtonText={'Confirm'} cancelButtonText={'Cancel'} handleConfirm={confirmationDeleteHandler} heading={'Are you sure want to delete this user account?'} />
            <NLModal isOpen={isOpenConfirmationModal} showInput handleModel={closeConfirmModal} showHeading showConfirmBUtton confirmButtonText={'Confirm'} cancelButtonText={'Cancel'} handleConfirm={userDeleteHandler} heading={'Confirmation code for account deletion.'}>
                <FieldInput label={'Code'} value={code} onChange={e => setCode(e.target.value)} type='number' placeholder='Enter the code for account deletion.' />
            </NLModal>
        </div>
    )
}
