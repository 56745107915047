import { Select } from 'antd';
import React from 'react';
import './SelectInput.scss';

export default function SelectInput({
    size,
    value,
    placeholder,
    handleChange,
    options,
    name,
    label,
    labelSize = true,
}) {
    return (
        <div className="select_inputContainer">
            <p className={labelSize ? 'labelStyle_large' : 'labelStyle_small'}>
                {label}
            </p>
            <Select
                size={size}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={handleChange}
                style={{
                    height: labelSize ? '48px' : '30px',


                }}
                options={options}
            />
        </div>
    );
}
