import React from 'react'
import { Input } from 'antd'
import './SearchInput.scss';

export default function SearchInput({ value, name, onChange }) {
    return (
        <div className='search_container'>
            <Input
                placeholder={`Type to search...`}
                prefix={
                    <img
                        src="/assets/images/search.svg"
                        height={20}
                        width={20}
                    />
                }
                value={value}
                name={name}
                onChange={onChange}
                className='searchInput'
            />
            <br />
        </div>
    );
}
