import React, { useEffect, useMemo, useState } from "react";
import { Menu } from "antd";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../../assets/images/LOGO.svg";


import "./Sidebar.scss";

export default function SideBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [selected, setSelected] = useState('')
    const sideBarTabs = useMemo(
        () => [
            {
                key: 1,
                link: '/dashboard',
                label: 'Dashboard',
                icon: (
                    <img
                        src="/assets/images/dashboard.svg"
                        alt="dashboard"
                        height={18}
                    />
                ),
            },
            {
                key: 2,
                link: '/dashboard/venues',
                label: 'Venues',
                icon: (
                    <img
                        src="/assets/images/Venue.png"
                        alt="venues"
                        height={18}
                    />
                ),
            },
            {
                key: 3,
                link: '/dashboard/venues-analytics',
                label: 'Venues Analytics',
                icon: (
                    <img
                        src="/assets/images/analytics.svg"
                        alt="analytics"
                        height={18}
                    />
                ),
            },
            {
                key: 4,
                link: '/dashboard/staff',
                label: 'Neverleft Staff Account Managment',
                icon: (
                    <img
                        src="/assets/images/userManagement.svg"
                        alt="userManagement"
                        height={18}
                    />
                ),
            },
            {
                key: 5,
                link: '/dashboard/user-managment',
                label: 'User Account Managment',
                icon: (
                    <img
                        src="/assets/images/user account Management.svg"
                        alt="useraccountManagement"
                        height={18}
                    />
                ),
            },
            {
                key: 6,
                link: '/dashboard/user-analytic',
                label: 'User Analytics',
                icon: (
                    <img
                        src="/assets/images/User analytics.svg"
                        alt="useranalytics"
                        height={18}
                    />
                ),
            },

            {
                key: 10,
                link: '/dashboard/setting',
                label: 'Settings',
                icon: (
                    <img
                        src="/assets/images/setting.svg"
                        alt="setting"
                        height={18}
                    />
                ),
            },
        ],
        [],
    );

    const onRedirect = ({ key }) => {
        const item = sideBarTabs.find((item) => item.key === parseInt(key));
        setSelected(key);
        navigate(item.link);
    }

    useEffect(() => {
        const selectedItem = sideBarTabs.find((item) => item.link === location.pathname);
        if (selectedItem) {
            setSelected(selectedItem.key.toString());
        }
    }, [location.pathname, sideBarTabs]);

    return (
        <>
            <div className="sideBarlogoContainer">
                <img src={Logo} alt="Logo" width={'200px'} />
            </div>
            <div className="demo-logo-vertical" />
            <Menu
                theme="dark"
                style={{
                    background: 'transparent'
                }}
                mode="inline"
                selectedKeys={[selected]}
                items={sideBarTabs}
                onClick={onRedirect}
            />
        </>
    );
}
