import { Spin } from 'antd';
import React from 'react';
import './Loader.scss'

export default function Loader() {
    return (
        <div className='spinLayout'>
            <Spin />
        </div>
    );
}
