import React from "react";
import { Button, Spin } from "antd";
import "./NLBtn.scss";

const NLBtn = ({ name, onClick, disabled, htmlType, isLoading }) => {
  return (
    <>
      <div className="buttonContainer">
        <Button
          onClick={onClick}
          className={disabled ? "disableButtonStyle" : "buttonStyle"}
          disabled={disabled}
          htmlType={htmlType ? htmlType : "button"}
        >
          {isLoading ? <Spin size="small" /> : name}
        </Button>
      </div>
    </>
  );
};

export default NLBtn;
