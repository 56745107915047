import { Col, Row, Typography } from 'antd';
import React from 'react';
import { NLBtn } from '../commonComponents';
const { Title } = Typography;

export default function TableHeader({
    title,
    showButton,
    status,
    buttonTitle,
    onClick,
    level = 5,
    className,
    styles = { color: '#fff' },
}) {
    return (
        <Row justify={'space-between'}>
            <Col>
                <Title level={level} style={styles}>
                    {title}
                </Title>
            </Col>
            <Col className="button-col-style">
                {showButton ? (
                    <NLBtn name={buttonTitle} onClick={onClick} />
                ) : (
                    <Title level={2} style={styles}>
                        {status}
                    </Title>
                )}
            </Col>
        </Row>
    );
}
