const actions = {
    ERROR: 'ERROR',
    BEGIN: 'BEGIN',
    SET_STAFF: 'SET_STAFF',
    CREATE_MEMBER: 'CREATE_MEMBER',
    STAFF_MEMBER: 'STAFF_MEMBER',

    begin: () => {
        return {
            type: actions.BEGIN,
        };
    },

    error: (err) => {
        return {
            type: actions.ERROR,
            err,
        };
    },

    setStaff: (data, total) => {
        return {
            type: actions.SET_STAFF,
            data,
            total,
        };
    },
    createMember: () => {
        return { type: actions.CREATE_MEMBER };
    },
    staffMember: (data) => {
        return { type: actions.STAFF_MEMBER, data };
    },
};

export default actions;
