import React from 'react'
import {Typography} from 'antd';

const {Title} = Typography;

export default function PageHeader({label, level = 1}) {
  return (
      <div
          style={{
              display: 'flex',
              justifyContent: 'start',
          }}
      >
          <Title
              level={level}
              style={{
                  color: '#fff',
                  whiteSpace: 'nowrap',
                  fontWeight: '300',
                  fontSize: '40px',
                  letterSpacing: '1px',
                  lineHeight: '48px',
              }}
          >
              {label}
          </Title>
      </div>
  );
}
