import React from "react";
import { Layout, Space } from 'antd';
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../../commonComponents";
import "./Layout.scss";
import Navbar from "../../commonComponents/Navbar";

const { Header, Sider, Content } = Layout;
const BaseLayout = ({ children }) => {
    return (
        <div className="layout-wrapper">
            <div
                // width={250}
                style={{
                    background: 'none',
                    height: '100vh',

                }}
            >
                <Sidebar />
            </div>
            <Layout
                style={{
                    backgroundColor: 'transparent',
                }}
            >
                <Header
                    className="headerStyle"
                    style={{
                        backgroundColor: 'transparent',
                    }}
                >
                    <div className="layoutNavbarStyle">
                        <Navbar />
                    </div>
                </Header>
                <Content
                    style={{
                        padding: '1rem',
                        height: '50vh',
                        overflowY: 'auto',
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </div>
    );
};

export default BaseLayout;
