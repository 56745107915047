import actions from './actions';

const { SET_STAFF, BEGIN, ERROR, CREATE_MEMBER, STAFF_MEMBER } = actions;

const initialState = {
    staffs: [],
    loading: false,
    error: null,
    total: 0,
    staffMember: null,
};

const staffReducer = (state = initialState, action) => {
    const { type, data, err, total } = action;
    switch (type) {
        case BEGIN:
            return {
                ...state,
                loading: true,
            };
        case ERROR:
            return {
                ...state,
                loading: false,
                error: err,
            };
        case SET_STAFF:
            return {
                ...state,
                staffs: data,
                total,
                loading: false,
            };
        case CREATE_MEMBER:
            return { ...state, loading: false };
        case STAFF_MEMBER:
            return { ...state, staffMember: data, loading: false };
        default:
            return state;
    }
};

export default staffReducer;
