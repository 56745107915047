/* eslint-disable react/prop-types */
import React, { useReducer, createContext, useContext } from "react";
import { ClubId } from "./reducers/ClubId";

// initial state
const initialState = {
  authToken: localStorage.getItem("access_token") || null,
  clubId: 0,
};

const Context = createContext(initialState);
const useAppState = () => useContext(Context);

const combineReducers =
  (...reducers) =>
  (state, action) => {
    for (let i = 0; i < reducers.length; i++)
      state = reducers[i](state, action);
    return state;
  };

const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(combineReducers(ClubId), initialState);
  const value = { state, dispatch };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { useAppState, AppStateProvider };
