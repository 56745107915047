import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown, message } from 'antd';
import './Staff.scss';
import TableHeader from '../../components/table/TableHeader';
import DataTable from '../../components/table/DataTable';
import TableWrapper from '../../components/table/TableWrapper';
import { blockStaffMember, deleteStaffMember, getStaffList } from '../../redux/staff/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router';
import TablePagination from '../../components/table/TablePagination';
import NLModal from '../../components/commonComponents/NLModal/NLModal';
import FieldInput from '../../components/field-input/FieldInput';
import { emailConfirmation, sendEmailOtp } from '../../redux/auth/actionCreator';

export default function Staff() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { staffs, isLoading, total, user } = useSelector((_state) =>
    ({
        staffs: _state.staff.staffs,
        isLoading: _state.staff.loading,
        total: _state.staff.total,
        user: _state.auth.user
    })
    );

    const [target, setTarget] = useState({
        id: '',
        status: false
    });
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const [code, setCode] = useState(null);
    const [startCount, setStartCount] = useState(1);

    const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

    const openBlockModal = () => setIsOpenBlockModal(true);
    const closeBlockModal = () => {
        setIsOpenBlockModal(false);
        setTarget({
            id: '',
            status: false
        });
    };
    const openDeleteModal = () => setIsOpenDeleteModal(true);
    const closeDeleteModal = () => {
        setIsOpenDeleteModal(false);
        setTarget({
            id: '',
            status: false
        });
    };

    const openConfirmModal = () => setIsOpenConfirmationModal(true);
    const closeConfirmModal = () => {
        setIsOpenConfirmationModal(false);
        setTarget('');
        setCode(null);
    };


    const items = useCallback(
        (id, payload) => [
            {
                key: '2',
                label: (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="#"

                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`/dashboard/staff/view/${id}`, {
                                state: {
                                    payload,
                                },
                            });
                        }}
                    >
                        Edit profile
                    </a>
                ),
            },
            {
                key: '3',

                label: (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="#"

                        onClick={(e) => {
                            e.preventDefault();
                            openBlockModal();
                            setTarget({ id, status: payload?.status !== "active" })
                        }}
                    >
                        {payload?.status === "active" ? "Block" : "Unblock"}

                    </a>
                ),
            },
            {
                key: '4',
                label: (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="#"
                        style={{ color: 'red' }}

                        onClick={(e) => {
                            e.preventDefault();
                            openDeleteModal();
                            setTarget({ id, status: payload?.status !== "active" })
                        }}
                    >
                        Delete Account
                    </a>
                ),
            },
        ],
        [],
    );


    const columns = [

        {
            title: 'Sr#',
            dataIndex: 'srno',
        },
        {
            title: 'Staff Member Name',
            dataIndex: 'firstName',
        },
        {
            title: 'Staff Email',
            dataIndex: 'email',
        },
        {
            title: 'Registration Date',
            dataIndex: 'createdAt',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Action',
            dataIndex: 'action',
        },
    ];

    const getStaffLists = useCallback(
        (page, pageSize) => {

            setStartCount(() => {
                dispatch(getStaffList((page - 1) * pageSize, pageSize));
                return pageSize * (page - 1);
            });
        },
        [],
    );

    const staffBlockHandler = () => {
        dispatch(blockStaffMember(target.id, target.status, (text) => {
            message.success(text);
            closeBlockModal()
        }))
    }

    const confirmationDeleteHandler = () => {
        dispatch(sendEmailOtp({
            email: user?.email,
            firstName: user?.firstName
        }))
        setIsOpenDeleteModal(false);
        setIsOpenConfirmationModal(true);
    }

    const staffDeleteHandler = () => {
        dispatch(emailConfirmation({
            code: parseInt(code), email: user?.email,
        }, (data) => {
            dispatch(deleteStaffMember(target.id, (text) => {
                message.success(text)
            }))
            setIsOpenConfirmationModal(false);
        }))
    }



    useEffect(() => {
        getStaffLists(1, 10);
    }, [getStaffLists]);


    return (
        <div style={{
            marginBottom: '1rem'
        }}>
            <TableWrapper>
                <TableHeader
                    title={'Neverleft Staff Account Management'}
                    className="neverLeftStaffAccountTitle"
                    level={2}
                    showButton={true}
                    buttonTitle={'+ Create  New  Staff  Account'}
                    onClick={() => {
                        navigate('/dashboard/staff/create')
                    }}
                />
                <DataTable columns={columns} loading={isLoading} callback={
                    Array.isArray(staffs) &&
                    staffs?.length > 0 &&
                    staffs?.map((row, index) => (
                        <tr key={index} className="table-row">

                            <td className='table-data'>{index + 1 + startCount}</td>
                            <td className='table-data'>{row?.firstName ?? '--'} {' '} {row?.lastName ?? '--'}</td>
                            <td className='table-data'>
                                {row.email}
                            </td>
                            <td className='table-data'>
                                {moment(row?.createdAt).format('YYYY/MM/DD hh:mm:ss') ?? '--'}
                            </td>
                            <td className='table-data'>{row?.status ?? '--'}</td>
                            <td className='table-data'>
                                <Dropdown
                                    menu={{
                                        items: items(row?._id, row),
                                    }}
                                    trigger={['click']}

                                >
                                    <a
                                        className="ant-dropdown-link"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <img src="/assets/images/Union.svg" />
                                    </a>
                                </Dropdown>
                            </td>
                        </tr>
                    ))
                } />
                <TablePagination total={total} isLoading={isLoading} onChange={(_page, _pageSize) => {
                    getStaffLists(_page, _pageSize);
                }} />
            </TableWrapper>
            <NLModal isOpen={isOpenBlockModal} handleModel={closeBlockModal} showHeading showConfirmBUtton showCancelBUtton confirmButtonText={'Confirm'} cancelButtonText={'Cancel'} handleConfirm={staffBlockHandler} heading={
                target.status
                    ? 'Are you sure want to unblock this staff account?'
                    : 'Are you sure want to block this staff account?'
            } />
            <NLModal isOpen={isOpenDeleteModal} handleModel={closeDeleteModal} showHeading showConfirmBUtton showCancelBUtton confirmButtonText={'Confirm'} cancelButtonText={'Cancel'} handleConfirm={confirmationDeleteHandler} heading={'Are you sure want to delete this staff account?'} />
            <NLModal isOpen={isOpenConfirmationModal} showInput handleModel={closeConfirmModal} showHeading showConfirmBUtton confirmButtonText={'Confirm'} cancelButtonText={'Cancel'} handleConfirm={staffDeleteHandler} heading={'Confirmation Code for account deletion.'}>
                <FieldInput label={'Code'} value={code} onChange={e => setCode(e.target.value)} type='number' placeholder='Enter the code for account deletion.' />
            </NLModal>
        </div>
    );
}
