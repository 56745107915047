import { Form, Row, Col, Checkbox, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import profile_add from '../../assets/images/profile-add.png';
import mailIcon from '../../assets/images/EMAIL.svg';

import { UserOutlined } from '@ant-design/icons';
import { InputField, NLBtn } from '../../components/commonComponents';
import PageHeader from '../../components/page-header/PageHeader';
import FieldInput from '../../components/field-input/FieldInput';
import { useDispatch, useSelector } from 'react-redux';
import { createStaffMember } from '../../redux/staff/actionCreator';
import { useNavigate } from 'react-router-dom';
import './StaffCreate.scss'

const options = [
    { value: "SOFTWARE_DEVELOPER", label: "Software Developer" },
    { value: "CLIENT_ACCOUNT_MANAGER", label: "Client Account Manager" },
    { value: "DATA_ANALYST", label: "Data Analyst" },
    { value: "HUMAN_RESOURCES_MANAGER", label: "Human Resources Manager" },
    { value: "FINANCIAL_ANALYST", label: "Financial Analyst" },
    { value: "EXECUTIVE_ACCOUNT", label: "Executive Account" },
    { value: "DATA_PROTECTION_OFFICER", label: "Data Protection Officer" }
];

const regex = {
    firstName: /^[a-zA-Z ]*$/,
    lastName: /^[a-zA-Z ]*$/,
    email: /^([+\w-]+(?:.[+\w-]+))@((?:[\w-]+.)\w[\w-]{0,66}).([a-z]{2,6}(?:.[a-z]{2})?)$/,
    password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
}

export default function StaffCreate() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { isLoading } = useSelector((_state) => ({
        isLoading: _state.staff.loading
    }));

    const [isTouched, setIsTouched] = useState({
        confirm_password: false
    })

    const [staffData, setStaffData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirm_password: '',
        permission: [],
        usertype: 'masterStaff'
    });

    const [errorData, setErrorData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirm_password: '',
        permission: ''
    })

    const onFinish = () => {
        dispatch(createStaffMember(staffData, (text, success) => {
            message.success(text);
            if (success) navigate('/dashboard/staff');
        }))
    }

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setStaffData(preStaff => ({ ...preStaff, [name]: value }));
        if (name === 'confirm_password') return;
        if (regex[name]?.test(value)) {
            setErrorData(preError => ({ ...preError, [name]: '' }));
        } else {
            setErrorData(preError => ({ ...preError, [name]: `Input ${name} is required.` }));
        }
    }

    const onChangeRoles = (value) => {
        setStaffData((preState) => {
            return ({
                ...preState,
                permission: [...value],
            })
        });
    }

    const focusHandler = (e) => {
        const { name } = e.target;
        setIsTouched(pre => ({ ...pre, [name]: true }))
    }

    const confirmPasswordChecker = useCallback(() => {
        if (staffData.password !== staffData.confirm_password) {
            setErrorData(preError => ({ ...preError, confirm_password: 'Password is not matched.' }))
        } else {
            setErrorData(preError => ({ ...preError, confirm_password: '' }))
        }
    }, [staffData.password, staffData.confirm_password])

    useEffect(() => {
        confirmPasswordChecker();
    }, [confirmPasswordChecker])

    const isDisable = useMemo(() => !Object.values(staffData).every((_) => _.length > 0), [staffData]);
    const hasError = useMemo(() => Object.values(errorData).some((value) => value.length > 0), [errorData]);



    return (
        <div style={{
            margin: '1rem 0'
        }}>
            <PageHeader label='Create New Staff Account' />
            <Form
                name="staff_account_form"
                className="staff-account-form"
                onFinish={onFinish}
                autoComplete="none"
            >
                <InputField
                    label={'Staff First Name *'}
                    required
                    placeholder="Enter First Name"
                    onChange={onChangeValue}
                    value={staffData.firstName}
                    name='firstName'
                    prefix={<UserOutlined />}
                    error={errorData.firstName}
                />
                <br />

                <InputField
                    label={'Staff Last Name *'}
                    required
                    placeholder="Enter Last Name"
                    onChange={onChangeValue}
                    value={staffData.lastName}
                    name={'lastName'}
                    prefix={<UserOutlined />}
                    error={errorData.lastName}
                />
                <br />

                <InputField
                    label={'Staff Email *'}
                    required
                    placeholder="Enter Staff Email "
                    onChange={onChangeValue}
                    value={staffData.email}
                    name={'email'}
                    prefix={<img src={mailIcon} alt='Mail Icon' />}
                    type='email'
                    error={errorData.email}

                />
                <br />
                <InputField
                    label={'Create Password *'}
                    required
                    placeholder="Please enter your password"
                    onChange={onChangeValue}
                    value={staffData.password}
                    name={'password'}
                    prefix={<img src='/assets/images/unlock.svg' alt='Mail Icon' />}
                    type='password'
                    error={errorData.password}
                />
                <br />
                <InputField
                    label={'Confirm Password *'}
                    required
                    placeholder="Please confirm your password"
                    onChange={onChangeValue}
                    value={staffData.confirm_password}
                    name={'confirm_password'}
                    prefix={<img src='/assets/images/unlock.svg' alt='Mail Icon' />}
                    type='password'
                    error={isTouched.confirm_password && errorData.confirm_password}
                    onFocusCapture={focusHandler}
                />
                <br />
                <div className="staff-feature-checkbox-heading">
                    Controlling Staff Member Roles
                </div>
                <Checkbox.Group
                    onChange={onChangeRoles}
                    style={{
                        width: '100%',
                    }}
                    value={staffData.permission}
                >
                    <Row
                        style={{
                            height: '100%',
                            marginBottom: '20px',
                        }}
                    >
                        {options.map((item, key) => (
                            <Col
                                span={12}
                                key={key}
                                style={{ textAlign: 'left' }}
                            >
                                <Checkbox
                                    value={item.value}
                                    style={{ marginBottom: '8px' }}
                                    className="custom-checkbox"
                                >
                                    {item.label}
                                </Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>

                <NLBtn
                    htmlType="submit"
                    disabled={isDisable || hasError}
                    name={'Create Account'}
                />
            </Form>
        </div>
    )
}
