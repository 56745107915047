import { DatePicker } from 'antd'
import React from 'react'


import './InputDate.scss'


export default function InputDate({ label,
    labelSize = true,
    light = true,
    name,
    value,
    placeholder = 'Choose date and time',
    onChange,
    backgroundColor,
    prefix,
    color,
    error,
    suffix,
    type = 'text',
    required = false,
    disabled = false }) {
    return (
        <div className="field_inputContainer">

            <p
                className={
                    labelSize ? 'labelStyle-large ' : 'labelStyle-small '
                }
            >
                {label} {required && <span>*</span>}
            </p>
            <DatePicker onChange={onChange} name={name} allowClear picker='date' placeholder={placeholder} value={value} suffixIcon={<img src='/assets/images/menu-board.svg' />} />
        </div>
    )
}
