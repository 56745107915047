import React from 'react'
import { Card, Typography, Progress } from 'antd';

import './Card.scss';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const formatProgress = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export default function CardCustom({ title = "Number of User", analyticNumber = 0, progress, img, path, progressTitle }) {
    const navigate = useNavigate();
    const redirectTo = () => { navigate(path) }

    return (
        <Card className='card-warpper'>
            <div className='card-heading-container'>
                <Title className='card-heading' onClick={redirectTo}>{title}</Title>
                <img src={img} height={24} width={24} />

            </div>
            <div className='card-analytics-number'>
                {isNaN(analyticNumber) ? analyticNumber : formatProgress(analyticNumber)}
            </div>
            <hr className='card-divider' />
            <div style={{ position: "relative" }}>

                <div className='progressPercent' style={{
                    // width: `${Math.floor(progress)}% !important`,
                    left: `${Math.floor(progress)}%`
                }}>
                    {/* </div> */}
                    <span style={{ marginLeft: -progress * 0.26 }}>
                        {`${Math.floor(isNaN(progress) ? 0 : progress)}%`}
                    </span>
                    <div className="line" style={{
                        left: `${Math.floor(progress)}%`,
                        marginLeft: -progress * 0.1
                    }}></div>
                </div>

                <Progress percent={Math.floor(progress)} showInfo={false} size={[300, 20]} style={{ marginTop: "2rem" }} />

                <p className='progressTitle'>
                    {progressTitle}
                </p>
            </div>
        </Card>
    )
}
