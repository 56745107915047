import React, { useEffect, useMemo, useState } from 'react'
import PageHeader from '../../components/page-header/PageHeader'
import { Checkbox, Col, Form, Row, message } from 'antd'
import FieldInput from '../../components/field-input/FieldInput'
import { InputField, NLBtn } from '../../components/commonComponents'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getStaffMember, updateStaffMember } from '../../redux/staff/actionCreator'
import moment from 'moment'

const options = [
    { value: "SOFTWARE_DEVELOPER", label: "Software Developer" },
    { value: "CLIENT_ACCOUNT_MANAGER", label: "Client Account Manager" },
    { value: "DATA_ANALYST", label: "Data Analyst" },
    { value: "HUMAN_RESOURCES_MANAGER", label: "Human Resources Manager" },
    { value: "FINANCIAL_ANALYST", label: "Financial Analyst" },
    { value: "EXECUTIVE_ACCOUNT", label: "Executive Account" },
    { value: "DATA_PROTECTION_OFFICER", label: "Data Protection Officer" }
];

const regex = {
    firstName: /^[a-zA-Z ]*$/,
    lastName: /^[a-zA-Z ]*$/,
    email: /^([+\w-]+(?:.[+\w-]+))@((?:[\w-]+.)\w[\w-]{0,66}).([a-z]{2,6}(?:.[a-z]{2})?)$/,
}

export default function StaffView() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { member } = useSelector((_state) =>
        ({ member: _state.staff.staffMember })
    );

    const [staffData, setStaffData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        permission: '',
        usertype: 'masterStaff'
    });

    const [errorData, setErrorData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        permission: ''
    });

    const onFinish = () => {
        dispatch(updateStaffMember({ userId: id, ...staffData }, () => {
            message.success('Staff Account have been updated.');
            navigate('/dashboard/staff')
        }))
    }

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setStaffData(preStaff => ({ ...preStaff, [name]: value }));
        if (name === 'confirm_password') return;
        if (regex[name]?.test(value)) {
            setErrorData(preError => ({ ...preError, [name]: '' }));
        } else {
            setErrorData(preError => ({ ...preError, [name]: `Input ${name} is requied.` }));
        }
    }

    useEffect(() => {
        if (id) {
            dispatch(getStaffMember(id));
        }
    }, [id])

    useEffect(() => {
        setStaffData({
            firstName: member?.firstName ?? '',
            lastName: member?.lastName ?? '',
            email: member?.email ?? '',
            permission: member?.permission ?? []
        })
    }, [member])

    const onChangeRoles = (value) => {
        setStaffData(preState => {
            return ({
                ...preState,
                permission: [...value]
            })
        })
    }

    const isDisable = useMemo(() => !Object.values(staffData).every((_) => _.length > 1), [staffData])
    const hasError = useMemo(() => !Object.values(errorData).every(value => value.length === 0), [errorData])


    return (
        <div>
            <PageHeader label={'Staff Account Profile'} />
            <div className="staff-form-style">
                <Form
                    name="staff_account_form"
                    className="staff-account-form"
                    onFinish={onFinish}
                >
                    <InputField
                        onChange={onChangeValue}
                        label='First Name'
                        value={staffData?.firstName}
                        name={'firstName'}
                        error={errorData?.firstName}
                    />
                    <br />
                    <InputField
                        onChange={onChangeValue}
                        label='Last Name'
                        value={staffData?.lastName}
                        error={errorData?.lastName}
                        name={'lastName'} /> <br />
                    <InputField onChange={onChangeValue} disabled error={errorData?.email} label='Email' value={staffData?.email} name={'email'} /> <br />
                    <InputField onChange={onChangeValue} disabled label='Registration Date' name={'createdAt'} value={moment(staffData?.createdAt).format('DD-MM-YYYY')} /> <br />
                    <div className="staff-feature-checkbox-heading">
                        Controlling Staff Member Roles
                    </div>
                    <Checkbox.Group
                        onChange={onChangeRoles}
                        style={{
                            width: '100%',
                        }}
                        value={staffData?.permission}
                    >
                        <Row
                            style={{
                                height: '100%',
                                marginBottom: '20px',
                            }}
                        >
                            {options.map((item, key) => (
                                <Col
                                    span={12}
                                    key={key}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Checkbox
                                        value={item.value}
                                        style={{ marginBottom: '8px' }}
                                        className="custom-checkbox"
                                    >
                                        {item.label}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>

                    <NLBtn
                        htmlType="submit"
                        disabled={isDisable || hasError}
                        // isLoading={isLoading}
                        name={'Update Account'}
                    />
                </Form>
            </div>
        </div>
    )
}
