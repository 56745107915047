/* eslint-disable no-console */
import React from "react";
import { Navigate } from "react-router-dom";
import Sidebar from "../components/commonComponents/Sidebar/Sidebar";
import { getToken } from "../utils/localStorage";
import BaseLayout from "../components/mainComponents/Layout";

const PrivateRoute = ({ children }) => {
  return getToken() ? <BaseLayout>{children}</BaseLayout> : <Navigate to="/login" />;
};

export default PrivateRoute;
