import React from 'react'
import ModalComponent from '../../commonComponents/NLModal/NLModal';


const Modal = () => {


    return (
        <>
            <ModalComponent
                bodyText="Your Account 
                deleted successfully"
                showButton='false'
                showIcon='true'
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
                    <path d="M40.5 70.1579C35.7777 70.1579 31.3888 69.3107 27.3333 67.6162C23.2777 65.9218 19.75 63.5745 16.75 60.5745C13.75 57.5745 11.4027 54.0468 9.70829 49.9912C8.01385 45.9357 7.16663 41.5468 7.16663 36.8245C7.16663 32.1579 8.01385 27.7968 9.70829 23.7412C11.4027 19.6857 13.75 16.1579 16.75 13.1579C19.75 10.1579 23.2777 7.79677 27.3333 6.07454C31.3888 4.35232 35.7777 3.49121 40.5 3.49121C44.6666 3.49121 48.5555 4.15788 52.1666 5.49121C55.7777 6.82454 59.0277 8.65788 61.9166 10.9912L58.3333 14.5745C55.8889 12.6301 53.1666 11.1301 50.1666 10.0745C47.1666 9.01899 43.9444 8.49121 40.5 8.49121C32.4444 8.49121 25.7083 11.1995 20.2916 16.6162C14.875 22.0329 12.1666 28.769 12.1666 36.8245C12.1666 44.8801 14.875 51.6162 20.2916 57.0329C25.7083 62.4495 32.4444 65.1579 40.5 65.1579C48.5555 65.1579 55.2916 62.4495 60.7083 57.0329C66.125 51.6162 68.8333 44.8801 68.8333 36.8245C68.8333 35.1579 68.7083 33.5329 68.4583 31.9495C68.2083 30.3662 67.8333 28.8245 67.3333 27.3245L71.1666 23.4912C72.0555 25.5468 72.7222 27.6857 73.1666 29.9079C73.6111 32.1301 73.8333 34.4357 73.8333 36.8245C73.8333 41.5468 72.9722 45.9357 71.25 49.9912C69.5277 54.0468 67.1666 57.5745 64.1666 60.5745C61.1666 63.5745 57.6389 65.9218 53.5833 67.6162C49.5277 69.3107 45.1666 70.1579 40.5 70.1579ZM35.5833 51.9912L21.8333 38.1579L25.5833 34.4079L35.5833 44.4079L70.0833 9.90788L73.9166 13.6579L35.5833 51.9912Z" fill="#15AA2C" />
                </svg>}
                showBodyText={true}
                showHeading={false}
                cancelButtonText='Cancel'
                confirmButtonText='Yes'
                heading=''
                showConfirmBUtton={false}
                showCancelBUtton={false}
                width={440}
                height='auto'
            />

            <ModalComponent

                bodyText="Your Subscription will be paused permanently and you’ll 
                lose access to NAVERLEFT benefits"
                showButton={true}
                showIcon={false}
                showBodyText={true}
                icon=''
                showHeading={true}
                cancelButtonText='Cancel'
                confirmButtonText='Confirm'
                heading='Are you sure you want to Pause the subscription?'
                showConfirmBUtton={true}
                showCancelBUtton={true}
                width={620}
                height='auto'
            />
            <ModalComponent

                bodyText=""
                showButton={true}
                showIcon={false}
                showBodyText={false}
                showHeading={true}
                icon=''
                cancelButtonText='Cancel'
                confirmButtonText='Yes,Block'
                heading='Are you sure you want to block client access?'
                showConfirmBUtton={true}
                showCancelBUtton={true}
                width={620}
                height='auto'
            />

        </>
    );

}
export default Modal

