import { Input } from 'antd';
import React from 'react';
import './FieldInput.scss';


export default function FieldInput({
    label,
    labelSize = true,
    light = true,
    name,
    value,
    placeholder,
    onChange,
    backgroundColor,
    prefix,
    color,
    error,
    suffix,
    type = 'text',
    required = false,
    disabled = false
}) {
    return (
        <div className="field_inputContainer">
            <p
                className={
                    labelSize ? 'labelStyle-large ' : 'labelStyle-small '
                }
            >
                {label} {required && <span>*</span>}
            </p>
            <Input
                placeholder={placeholder}
                name={name}
                value={value}
                type={type}
                onChange={onChange}
                disabled={disabled}
                autoComplete="none"
                prefix={prefix ? prefix : null}
                suffix={suffix ? suffix : null}
                style={{
                    height: labelSize ? '48px' : '30px',


                }}
                className={labelSize ? 'inputStyle-large coloredInput' : 'inputStyle-small coloredInput inputBorder'}
            />
            {error && <p className={
                labelSize ? 'labelStyle-large error_input ' : 'labelStyle-small error_input'
            }>{error}</p>}
        </div>
    );
}
