import { combineReducers } from 'redux';
import venuesReducer from './venues/reducers';
import authReducer from './auth/reducers';
import staffReducer from './staff/reducers';
import dashboardReducer from './dashboard/reducers';
import userReducer from './userManagement/reducers';

const rootReducers = combineReducers({
    venues: venuesReducer,
    auth: authReducer,
    staff: staffReducer,
    dashboard: dashboardReducer,
    userList: userReducer,
});

export default rootReducers;
