import React, { useState, useEffect } from "react";
import { Modal, Spin, Button, Row, Col } from "antd";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getApiWithAuth } from "../../../utils/api";
import { Url } from "../../../utils/apiUrl";
import "react-datepicker/dist/react-datepicker.css";
import "./Analytics.scss";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";

export default function InstituteAnalytics() {
  ChartJS.register(...registerables);
  const [isLoadingInstitute, setIsLoadingInstitute] = useState(false);
  const [institute, setInstitute] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedInstituteStudents, setSelectedInstituteStudent] = useState([]);
  const [formattedDate, setFormattedDate] = useState(
    moment(startDate).format("YYYY-MM-DD")
  );
  const [selectedButton, setSelectedButton] = useState("date");
  const [isLoading, setIsLoading] = useState({
    fetchClubLoader: false,
    chartLoader: false,
  });
  const [selectedStudent, setSelectedStudent] = useState("Select Student");
  const [chartData, setChartData] = useState([]);
  const handleButtonFilterClick = (type, format) => {
    setSelectedButton(type);
    setFormattedDate(moment(startDate).format(format));
  };

  const handleButtonGoClick = () => {
    setChartData([]);
    if (Object.keys(selectedInstitute).length > 0) {
      getQrCount();
    }
  };

  const settingOptions = (data) => {
    let optionArray = [];
    data.map((student) => {
      optionArray.push({
        value: student._id,
        label: student.Email,
      });
    });
    return optionArray;
  };

  const getAllInstitute = async () => {
    setIsLoadingInstitute(true);
    const response = await getApiWithAuth(Url.InstituteUrl);
    if (response.success) {
      setInstitute(response.data);
    }
    setIsLoadingInstitute(false);
  };

  const getSelectedInstituteStudent = async () => {
    const response = await getApiWithAuth(
      `${Url.instituteStudent}${selectedInstitute._id}`
    );
    if (response.success && response.data.length > 0) {
      setSelectedInstituteStudent(settingOptions(response.data));
    } else if (response.success && response.data.length == 0) {
      setSelectedInstituteStudent([]);
    }
  };

  const getQrCount = async () => {
    setIsLoading({ ...isLoading, chartLoader: true });
    const response = await getApiWithAuth(
      `${Url.qrCount}${selectedButton}=${formattedDate}&id=${selectedStudent}`
    );
    if (response.success) {
      if (selectedButton == "year") {
        setChartData(getMonthCount(response.data));
      } else if (selectedButton == "month")
        setChartData(getDayCount(response.data));
      else setChartData(getHoursCount(response.data));
    }
    setIsLoading({ ...isLoading, chartLoader: false });
  };

  const getHoursCount = (data) => {
    const result = [];
    for (const key in data) {
      let counter = 0;
      data[key].days.map((item) => {
        item.Count.map((item1) => {
          counter += item1.count;
        });
      });
      result.push({ id: key, count: counter });
    }
    return result;
  };

  const getDayCount = (data) => {
    const result = [];
    data.map((day) => {
      let counterDay = 0;
      day.count.map((dayCount) => {
        counterDay += dayCount.count;
      });
      result.push({ id: day._id, count: counterDay });
    });
    return result;
  };

  const getMonthCount = (data) => {
    const result = [];
    data.map((item) => {
      let counterHours = 0;
      item.days.map((day) => {
        day.Count.map((counter) => {
          counterHours += counter.count;
        });
      });
      result.push({ id: item.month, count: counterHours });
    });

    return result;
  };

  const onselect = (event) => {
    setSelectedStudent(event.target.value);
  };

  const showFilters = () => {
    return (
      <div>
        <div className="modalTopContainer1">
          <div className="timeButtonContainer">
            <Button
              type="primary"
              style={{
                backgroundColor: "#5ce1e6",
                border: " 1px solid #5ce1e6",
              }}
              onClick={() => handleButtonFilterClick("date", "YYYY-MM-DD")}
              className={
                selectedButton === "date"
                  ? "btnStyle selected-button"
                  : "btnStyle"
              }
            >
              Date
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: "#5ce1e6",
                border: " 1px solid #5ce1e6",
              }}
              onClick={() => handleButtonFilterClick("month", "YYYY-MM")}
              className={
                selectedButton === "month"
                  ? "btnStyle selected-button"
                  : "btnStyle"
              }
            >
              Month
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: "#5ce1e6",
                border: " 1px solid #5ce1e6",
              }}
              onClick={() => handleButtonFilterClick("year", "YYYY")}
              className={
                selectedButton === "year"
                  ? "btnStyle selected-button"
                  : "btnStyle"
              }
            >
              Year
            </Button>
          </div>
          <div style={{ width: "30%", marginLeft: "20px" }}>
            {selectedButton === "date" ? (
              <DatePicker
                maxDate={new Date()}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy-MM-dd"
              />
            ) : selectedButton === "month" ? (
              <DatePicker
                maxDate={new Date()}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy-MM"
                showMonthYearPicker
              />
            ) : (
              <DatePicker
                maxDate={new Date()}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy"
                showYearPicker
              />
            )}
          </div>
          <select
            placeholder="Select Student"
            className="selectOptions"
            onChange={(event) => onselect(event)}
          >
            <option value="">Select Student</option>
            {selectedInstituteStudents.map((item) => {
              return <option value={item.value}>{item.label}</option>;
            })}
          </select>

          <Button
            type="primary"
            style={{
              backgroundColor: "#5ce1e6",
              border: " 1px solid #5ce1e6",
              width: "15%",
            }}
            className="btnStyle"
            onClick={handleButtonGoClick}
          >
            Go
          </Button>
        </div>
        {showChart()}
      </div>
    );
  };

  const showChart = () => {
    let labelArr;
    if (selectedButton == "year") {
      labelArr = chartData.map((item) => {
        return moment()
          .month(item.id - 1)
          .format("MMM");
      });
    } else {
      labelArr = chartData.map((item) => {
        return item.id;
      });
    }

    let qrCount = chartData.map((item) => {
      return item.count;
    });

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
      },
    };

    const data = {
      labels: labelArr,
      datasets: [
        {
          label: "QR Count",
          data: qrCount,
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };

    return (
      <>
        {chartData.length > 0 ? (
          <div className="chartDiv">
            <Line data={data} options={options} />
          </div>
        ) : isLoading.chartLoader ? (
          <div className="noDataStyleAnalytics">
            <Spin tip="Loading" size="large" style={{ width: "100%" }} />
          </div>
        ) : (
          <div>
            <p className="noDataStyleAnalytics">NO DATA</p>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    getAllInstitute();
  }, []);

  useEffect(() => {
    setSelectedInstituteStudent([]);
    setSelectedStudent("Select Student");
  }, [showModal]);

  useEffect(() => {
    if (selectedButton === "date") {
      setFormattedDate(moment(startDate).format("YYYY-MM-DD"));
    } else if (selectedButton === "month") {
      setFormattedDate(moment(startDate).format("YYYY-MM"));
    } else {
      setFormattedDate(moment(startDate).format("YYYY"));
    }
  }, [startDate]);

  useEffect(() => {
    if (Object.keys(selectedInstitute).length > 0)
      getSelectedInstituteStudent();
  }, [selectedInstitute]);

  return (
    <>
      <div className="analyticsContainer">
        <div
          className="homePageOuterContainer analyticsW100"
          style={{ paddingBottom: "10px" }}
        >
          <div className="homepageHeading institutePageHeading">
            <h1>INSTITUTE ANALYTICS</h1>
          </div>

          {isLoadingInstitute ? (
            <Spin tip="Loading" size="large" />
          ) : (
            <Row
              gutter={[16, 20]}
              style={{ marginTop: "5%" }}
              className="clubAnalyticsContainer"
            >
              {institute.map((item) => (
                <Col lg={6} md={8}>
                  <div
                    className="clubCardMargin"
                    onClick={() => {
                      setSelectedInstitute(item);
                      setShowModal(true);
                    }}
                  >
                    <div className="clubcard1">
                      <div className="clubcardInner">
                        <div>
                          <h1 className="clubinstitution">{item.name}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </div>
      <Modal
        width={800}
        title={selectedInstitute.name}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
      >
        {showFilters()}
      </Modal>
    </>
  );
}
