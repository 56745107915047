import {
    deleteApiWithAuth,
    getApiWithAuth,
    postApiWithAuth,
    putApiWithAuth,
} from '../../utils/api';
import { Url } from '../../utils/apiUrl';
import actions from './actions';

const { setStaff, begin, error, createMember, staffMember } = actions;

const getStaffList = (page = 1, pageSize = 10) => {
    return async (dispatch) => {
        try {
            dispatch(begin());
            const { data } = await getApiWithAuth(
                Url.adminStaffList + `?skip=${page}&limit=${pageSize}`,
            );

            if (data.success) {
                const { masterStaff, totalItems } = data.data;
                dispatch(setStaff(masterStaff, totalItems));
            } else {
                dispatch(error(data?.message));   
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const createStaffMember = (values, callback) => {
    return async (dispatch) => {
        try {
            dispatch(begin());
            const { data } = await postApiWithAuth(Url.createStaff, values);

            if (data.success) {
                callback(data.data, data.success);
                dispatch(createMember());
            } else {
                callback(data?.message, data.success);
                dispatch(error(data?.message));
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const getStaffMember = (id) => {
    return async (dispatch) => {
        try {
            dispatch(begin());
            const { data } = await getApiWithAuth(Url.adminStaff + id);
            if (data.success) {
                dispatch(staffMember(data.data));
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const blockStaffMember = (id, status, callback) => {
    return async (dispatch) => {
        try {
            const { data: datablock } = await putApiWithAuth(
                Url.blockMember + `?userId=${id}&access=${status}`,
            );
            if (datablock?.Message) {
                callback(datablock?.Message);
                const { data } = await getApiWithAuth(Url.adminStaffList);
                if (data) {
                    const { masterStaff, total } = data;
                    dispatch(setStaff(masterStaff, total));
                }
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const deleteStaffMember = (id, callback) => {
    return async (dispatch) => {
        try {
            const { data } = await deleteApiWithAuth(Url.deleteMember + id);
            // console.log(data);
            if (data?.success) {
                callback(data?.data);
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const updateStaffMember = (payload, callback) => {
    return async (dispatch) => {
        try {
            const { data } = await putApiWithAuth(Url.updateMember, payload);
            if (data) {
                callback();
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

export {
    getStaffList,
    createStaffMember,
    getStaffMember,
    blockStaffMember,
    deleteStaffMember,
    updateStaffMember,
};
