import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Switch, Typography, message } from 'antd';
import PageHeader from '../../components/page-header/PageHeader';
import './VenuesDetail.scss';
import moment from 'moment';
import { UserOutlined, PushpinOutlined, ClockCircleOutlined, DollarCircleOutlined, HourglassFilled, HourglassOutlined, BorderlessTableOutlined } from '@ant-design/icons';
import SwitchInput from '../../components/switch-input/SwitchInput';
import TabBar from '../../components/tab-bar/TabBar';
import { useLocation, useParams } from 'react-router';
import FieldInput from '../../components/field-input/FieldInput';
import { NLBtn } from '../../components/commonComponents';
import { InputField } from '../../components/commonComponents';
import {
    addVenueDiscount,
    addVenueFreeTrail,
    clubAccess,
    setVenueDetail,
    venuePauseSubscription,
} from '../../redux/venues/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import TooltipPopup from '../../components/tool-tip/TooltipPopup';
import Loader from '../../components/loader/Loader';
import NLModal from '../../components/commonComponents/NLModal/NLModal';

const { Title } = Typography;

const pagesMetaData = [
    {
        label: 'Profile Info',
        value: 1,
    },
    {
        label: 'Account Access',
        value: 2,
    },
    {
        label: 'Discounts & Benefits',
        value: 3,
    },
    // {
    //     label: 'Venue Analytics',
    //     value: 4,
    // },
];

export default function VenuesDetail() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { payload, isLoading } = useSelector((_state) => ({
        payload: _state?.venues?.venueProfile,
        isLoading: _state?.venues?.loading,
    }));
    const [buttonLabel, setButtonLabel] = useState('Pause');
    const [blockButtonLabel, setBlockButtonLabel] = useState('Yes, Unblock');

    const [isPaused, setIsPaused] = useState(false);
    const [isOpenPausedModal, setIsOpenPausedModel] = useState(false);
    const [isOpenBlockModal, setIsOpenBlockModel] = useState(false);

    const [isBlocked, setIsBlocked] = useState(false);
    const [tabSwitch, setTabSwitch] = useState(1);

    const [giveDiscountForm, setGiveDiscountForm] = useState({
        amount: null,
        duration_in_months: null,
    });

    const [freeTrailForm, setFreeTrailForm] = useState({
        days: null,
    });

    const pauseModalHandler = () => setIsOpenPausedModel(pre => !pre);
    const blockModalHandler = () => setIsOpenBlockModel(pre => !pre);

    const onChangeGiveDiscountHandler = (event) => {
        const { name, value } = event.target;
        setGiveDiscountForm({ ...giveDiscountForm, [name]: value });
    };

    const onFreeTrailHandler = (event) => {
        const { name, value } = event.target;
        setFreeTrailForm({ ...freeTrailForm, [name]: value });
    };

    const onPauseSubcription = () => {
        setIsPaused(!isPaused);
        const newButtonLabel = isPaused ? 'Resume' : 'Pause';
        setButtonLabel(newButtonLabel);
        dispatch(venuePauseSubscription(id, (text) => {
            message.success(text)
        }));
        pauseModalHandler();
    };



    const handleClubAccess = () => {
        setIsBlocked((pre) => {
            return !pre;
        });
        console.log(isBlocked)
        const newBlockButtonLabel = isBlocked ? 'Yes, Unblock' : 'Yes, Block';
        console.log(newBlockButtonLabel)
        setBlockButtonLabel(newBlockButtonLabel);
        const newStatus = isBlocked ? 'active' : 'inactive';
        dispatch(clubAccess(id, newStatus, (text) => {
            message.success(text);
        }));
        blockModalHandler();
    };

    const onGiveDiscount = () => {
        dispatch(addVenueDiscount(id, giveDiscountForm, (text, success) => {
            if (success) {

            } else {
                message.error(text)
            }
        }));
    };

    const onFreeTrail = () => {
        dispatch(
            addVenueFreeTrail(id, freeTrailForm, (text, success) => {
                if (success) {
                    message.success(text);
                } else {
                    message.error(text);
                }
            }),
        );
    };

    useEffect(() => {
        if (id) {
            dispatch(setVenueDetail(id));
        }
    }, [id]);


    useEffect(() => {
        const newBlockButtonLabel = isBlocked ? 'Yes, Unblock' : 'Yes, Block';
        setBlockButtonLabel(newBlockButtonLabel);
    }, [isBlocked]);

    useEffect(() => {
        setIsPaused(payload?.subscriptionData?.is_paused);
        setIsBlocked(payload?.subscriptionData?.status === 'active');
    }, [isLoading]);
    console.log("---------------------DDDD", payload)
    const gridData = [
        {
            src: "/assets/images/menu-board-white.svg", title: "Register Date:", value: moment(payload?.clubData?.createdAt).format(
                'YYYY/MM/DD hh:mm:ss',
            )
        },
        { src: "/assets/images/location.svg", title: "Address:", value: payload?.clubData?.address },
        { src: "/assets/images/sms.svg", title: "Registered Email:", value: payload?.clubData?.email },
        { src: "/assets/images/call.svg", title: "Registered Cell #:", value: payload?.clubData?.number },
        { src: "/assets/images/global.svg", title: "Website:", value: payload?.clubData?.website || 'N.A' },
    ];


    return (
        <div className='venueDetailsFontSize'>
            <PageHeader label={"Venue Details"} />
            <TabBar
                activePage={tabSwitch}
                setActivePage={setTabSwitch}
                pagesMetaData={pagesMetaData}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>

                    {' '}
                    {tabSwitch === 1 && (
                        <div className="profile-detail-wrapper">

                            <Row gutter={16}>
                                <Col span={12}>
                                    <img
                                        className='profile-detail-wrapper-img'
                                        src={
                                            payload?.clubData?.logoUrl?.find(
                                                (item) => item?.is_primary === true,
                                            )?.url ?? '/assets/images/default.jpg'
                                        }
                                        alt="venues detail"
                                        width={570}
                                        height={295}
                                    />

                                </Col>
                                {payload?.subscriptionData?.planDetails && <Col span={12} className='billingContainerColVenueDetails' >
                                    <div className='billingContainerVenueDetails'>
                                        <div className='billingTitleVenueDetails'>
                                            {payload?.subscriptionData?.planDetails?.name || "PURPLE"}
                                        </div>
                                        <div className='billingSubscriptionContainerVenueDetails'>
                                            <span className='billingSubscriptionPriceVenueDetails'>
                                                £{payload?.subscriptionData?.planDetails?.price || 0}
                                            </span>
                                            <span className='billingSubscriptionPrice2VenueDetails'>
                                                /mo
                                            </span>
                                        </div>
                                        <div className='billingSubscriptionDateVenueDetails'>Renews on  {moment(payload?.subscriptionData?.endAt).format(
                                            'DD/MM/YYYY',
                                        )}</div>
                                    </div>
                                </Col>}
                            </Row>
                            <Title
                                style={{
                                    color: '#fff',
                                    fontWeight: 300,
                                    textAlign: 'left',
                                }}
                            >
                                {payload?.clubData?.name}
                            </Title>

                            <table>
                                <tbody>
                                    {gridData.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'left', minWidth: 200 }} >
                                                <span>
                                                    <img src={item.src} />
                                                    {' '} {item.title}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: 'left', minWidth: 200 }} >{item.value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* <div className="profile-detail">
                                <div className="profile-detail-row" key={1}>
                                    <span className="profile-detail-label">
                                        {' '}
                                        <img src="/assets/images/menu-board-white.svg" />{' '}
                                        Register Date:
                                    </span>
                                    <span>
                                        {moment(payload?.clubData?.createdAt).format(
                                            'YYYY/MM/DD hh:mm:ss',
                                        )}
                                    </span>
                                </div>
                                <div className="profile-detail-row" key={2}>
                                    <span className="profile-detail-label">
                                        {' '}
                                        <img src="/assets/images/location.svg" />{' '}
                                        Address:
                                    </span>
                                    <span>{payload?.clubData?.address}</span>
                                </div>
                                <div className="profile-detail-row" key={3}>
                                    <span className="profile-detail-label">
                                        <img src="/assets/images/sms.svg" />{' '}
                                        Registered Email:
                                    </span>
                                    <span>{payload?.clubData?.email}</span>
                                </div>
                                <div className="profile-detail-row" key={4}>
                                    <span className="profile-detail-label">
                                        {' '}
                                        <img src="/assets/images/call.svg" />{' '}
                                        Registered Cell #:
                                    </span>
                                    <span>{payload?.clubData?.number}</span>
                                </div>
                                <div className="profile-detail-row" key={5}>
                                    <span className="profile-detail-label">
                                        <img src="/assets/images/global.svg" />{' '}
                                        Website:
                                    </span>
                                    <span>{payload?.clubData?.website || 'N.A'}</span>
                                </div>
                            </div> */}
                        </div>
                    )}
                    {tabSwitch === 2 && (
                        <div className="account-access-control">
                            <PageHeader
                                label={'Account Access'}
                                level={2}
                            />
                            <div className="profile-detail">
                                {/* <div className="profile-detail-row">
                                    {payload?.subscriptionData ? (<><span className="profile-detail-labeled">
                                        {isPaused ? 'Unpause Subscription' : 'Pause Subscription'}
                                        Pause Subscription
                                    </span>
                                        <span>
                                            <TooltipPopup title="Club is not purchase any subscription">
                                                <SwitchInput
                                                    checked={!isPaused}
                                                    onChange={() => setIsOpenPausedModel(true)}
                                                    disabled={
                                                        !payload?.subscriptionData || isLoading
                                                    }
                                                />
                                            </TooltipPopup>
                                        </span></>) :
                                        <span className="noDataText profile-detail-labeled">
                                            No Active Subscription
                                        </span>}
                                </div> */}
                                <div className="profile-detail-row">
                                    <span className="profile-detail-labeled">
                                        {/* {isBlocked ? 'Unblock Client Access' : 'Block Client Access'} */}
                                        Block Client Access
                                    </span>
                                    <span>
                                        <SwitchInput
                                            checked={isBlocked}
                                            onChange={blockModalHandler}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {tabSwitch === 3 && (
                        <div className="profile-detail-wrapper">
                            <Row className="menu-wrapper">
                                <Col span={24} className="left-menu">
                                    <Title
                                        level={3}
                                        style={{
                                            color: '#fff',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Venue Discounts
                                    </Title>
                                </Col>
                                <Col span={24} className="right-menu">
                                    <img
                                        src="/assets/images/edit.svg"
                                        align="right"
                                    />
                                </Col>
                            </Row>

                            <Form className="form-wrapper">
                                <Row>
                                    <Col span={24}>
                                        <InputField
                                            label={'Free Days *'}
                                            placeholder={
                                                'Enter the number of free days'

                                            }
                                            type="number"
                                            name={'days'}
                                            value={freeTrailForm.days}
                                            onChange={onFreeTrailHandler}
                                            prefix={<ClockCircleOutlined style={{ color: '#6C757D' }} />}
                                            backgroundColor={'white'}
                                        />
                                        <br />
                                    </Col>
                                    <Col span={24}>
                                        <InputField
                                            label={
                                                'Amount Discounted (Every Month)'
                                            }
                                            placeholder={
                                                'Enter the discounted amount'
                                            }
                                            type="number"
                                            name="amount"
                                            value={giveDiscountForm.amount}

                                            onChange={
                                                onChangeGiveDiscountHandler
                                            }
                                            prefix={<DollarCircleOutlined style={{ color: '#6C757D' }} />}
                                        />
                                        <br />
                                    </Col>

                                    <Col span={24}>
                                        <InputField
                                            label={
                                                'Duration Of Discount (In Months)'
                                            }
                                            placeholder={
                                                'Enter the number of months'
                                            }
                                            type="number"
                                            name="duration_in_months"
                                            value={
                                                giveDiscountForm.duration_in_months
                                            }
                                            onChange={
                                                onChangeGiveDiscountHandler
                                            }
                                            prefix={<HourglassOutlined style={{ color: '#6C757D' }} />}

                                        />
                                        <br />
                                    </Col>
                                    <Col span={24}>
                                        <NLBtn
                                            name={'Confirm'}
                                            onClick={onGiveDiscount}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                            <br />


                            <Row className="menu-wrapper">
                                <Col span={24} className="left-menu">
                                    <Title
                                        level={3}
                                        style={{
                                            color: '#fff',
                                            textAlign: 'left',

                                        }}
                                    >
                                        Venue Benefits
                                    </Title>
                                </Col>

                            </Row>


                            <Form className="form-wrapper">
                                <Row>
                                    <Col span={24}>
                                        <InputField
                                            label={'Free Staff Accounts *'}
                                            placeholder={
                                                'Enter the number of free staff account'
                                            }
                                            type="number"
                                            name="days"
                                            value={freeTrailForm.days}
                                            onChange={onFreeTrailHandler}
                                            backgroundColor={'white'}
                                            prefix={<BorderlessTableOutlined style={{ color: '#6C757D' }} />}
                                        />
                                        <br />
                                    </Col>
                                    <Col span={24}>
                                        <NLBtn name={'Confirm'} onClick={onFreeTrail} />
                                    </Col>
                                </Row>
                            </Form>
                            <br />

                            <Row justify={'space-between'}>
                                <Col md={21} sm={20} style={{ textAlign: 'left' }}>
                                    <p className='giveAccessToCustomer'>Give Free Access To Customer Behaviour Analytics?</p>
                                </Col>
                                <Col md={3} sm={4}>
                                    <p> <Switch /></p>
                                </Col>
                            </Row>
                        </div>
                    )}
                </>
            )}

            <NLModal handleConfirm={onPauseSubcription} handleModel={pauseModalHandler} cancelButtonText={'Cancel'} confirmButtonText={'Confirm'} showConfirmBUtton showCancelBUtton showHeading showBodyText
                heading={
                    isPaused
                        ? 'Are you sure you want to Resume the subscription?'
                        : 'Are you sure you want to Pause the subscription?'
                } isOpen={isOpenPausedModal} />

            <NLModal handleConfirm={handleClubAccess} handleModel={blockModalHandler} cancelButtonText={'Cancel'} confirmButtonText={blockButtonLabel} showConfirmBUtton showCancelBUtton showHeading
                heading={
                    isBlocked
                        ? 'Are you sure you want to Unblock client access?'
                        : 'Are you sure you want to Block client access?'
                } isOpen={isOpenBlockModal} />
        </div>
    );
}
