import actions from './actions';

const { SET_USER, BEGIN, ERROR, USER_LOGOUT } = actions;

const initialState = {
    user: null,
    loading: false,
    error: null,
};

const authReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case BEGIN:
            return {
                ...state,
                loading: true,
            };
        case ERROR:
            return {
                ...state,
                loading: false,
                error: err,
            };
        case SET_USER:
            return {
                ...state,
                user: data,
                loading: false,
            };
        case USER_LOGOUT:
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
};

export default authReducer;
