import React, { useLayoutEffect, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addUserPayload, logoutUser } from '../../redux/auth/actionCreator';
import { DownOutlined } from '@ant-design/icons';
import { deleteToken } from '../../utils/localStorage';
import "./UserMenu.scss";


export default function UserMenu() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, userData } = useSelector((_state) =>
    ({
        isLoading: _state?.auth?.loading,
        userData: _state?.auth?.user,
    })
    );

    useLayoutEffect(() => {
        if (!userData) {
            dispatch(addUserPayload(() => {
                deleteToken();
                dispatch(logoutUser());
                console.clear();
                navigate('/');
            }));
        }
    }, [pathname]);

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        key: '1',
                        label: (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/dashboard/setting')
                                }}
                            >
                                Setting
                            </a>
                        ),
                    },
                    {
                        key: '2',
                        label: (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    deleteToken();
                                    dispatch(logoutUser());
                                    navigate('/');
                                }}
                            >
                                Logout
                            </a>
                        ),
                    },
                ],
            }}
            trigger={['click']}
        >

            <DownOutlined
                className='downArrowFontSize'
                style={{
                    color: '#fff',
                    fontWeight: 'lighter',
                    textTransform: 'capitalize',
                    fontSize: 10
                }}
            />
        </Dropdown>
    );
}
