import React, { useLayoutEffect, useMemo } from 'react'
import PageHeader from '../../components/page-header/PageHeader'
import { Col, Grid, Row } from 'antd'
import Card from '../../components/card/Card'
import './Dashboard.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getAnalytics } from '../../redux/dashboard/actionCreator'

const cardIcon = [
  "/assets/images/userManagement.svg",
  "/assets/images/fav-groupcalss.svg",
  "/assets/images/downbload.svg",
  "/assets/images/downbload.svg",
  "/assets/images/Venue.png",
  "/assets/images/Venue.png",
  "/assets/images/Revenue.svg",
  "/assets/images/Revenue.svg",
  "/assets/images/Venue.png",
]

const calculatePercntage = (value = 0, total = 0) => (value / total) * 100;

export default function Dashboard() {
  const dispatch = useDispatch();
  const { appStats, userStats, busiestVenueStats, coatStats, venueStats } = useSelector(_state =>
  ({
    appStats: _state.dashboard.analytics?.appStats,
    userStats: _state.dashboard.analytics?.userStats,
    busiestVenueStats: _state.dashboard.analytics?.busiestVenueStats,
    coatStats: _state.dashboard.analytics?.coatStats,
    venueStats: _state.dashboard.analytics?.venueStats,
  }))


  const cardData = useMemo(() => [
    {

      title: 'Total number of Users',
      img: '/assets/images/userManagement.svg',
      progress: calculatePercntage(userStats?.todayUsers, userStats?.totalUsers),
      analyticNumber: userStats?.totalUsers,
      progressTitle: 'Today',
      path: '/dashboard/user-managment',
    },
    {
      title: 'Number of Active Users',
      img: '/assets/images/fav-groupcalss.svg',
      progress: calculatePercntage(userStats?.todayActiveUsers, userStats?.totalActiveUsers),
      analyticNumber: userStats?.totalActiveUsers,
      progressTitle: 'Total',
      path: '/dashboard/user-managment',
    },
    {
      title: 'Number of App Downloads (iOS)',
      img: '/assets/images/downbload.svg',
      progress: calculatePercntage(appStats?.downloads, userStats?.totalUsers),
      analyticNumber: appStats?.downloads,
      progressTitle: 'Today',
      // path: '/dashboard/venues'
    },
    {
      title: `Number of App Downloads (Android)`,
      img: '/assets/images/downbload.svg',
      progress: calculatePercntage(appStats?.downloads, userStats?.totalUsers),
      analyticNumber: appStats?.downloads,
      progressTitle: 'Total',
      // path: '/dashboard/venues'
    },
    {
      title: 'Number of Venues Registered',
      img: '/assets/images/Venue.png',
      progress: calculatePercntage(userStats?.todayUsers, userStats?.totalUsers),
      analyticNumber: venueStats?.venuesRegisteredForever,
      progressTitle: 'Today',
      // path: '/dashboard/venues'
    },
    {
      title: 'No of Venues Paying Subscriptions',
      img: '/assets/images/Venue.png',
      progress: calculatePercntage(userStats?.todayUsers, userStats?.totalUsers),
      analyticNumber: venueStats?.subscribedVenuesCount,
      progressTitle: 'Today',
      // path: '/dashboard/venues'
    },
    {
      title: 'Items Stored in Cloakrooms Today',
      img: '/assets/images/Revenue.svg',
      progress: calculatePercntage(coatStats?.coatsToday, coatStats?.coats),
      analyticNumber: coatStats?.coatsToday,
      progressTitle: 'Today',
      // path: '/dashboard/venues'
    },
    {
      title: 'Total No of Items Stored in Cloakrooms',
      img: '/assets/images/Revenue.svg',
      progress: calculatePercntage(coatStats?.coatsToday, coatStats?.coats),
      analyticNumber: coatStats?.coats,
      progressTitle: 'Total',
      // path: '/dashboard/venues'
    },
    {
      title: 'Busiest Venue Name',
      img: '/assets/images/Venue.png',
      progress: calculatePercntage(busiestVenueStats?.busiestVenueTodayTicketCount, busiestVenueStats?.busiestVenueYesterdayTicketCount),
      analyticNumber: Boolean(busiestVenueStats?.busiestVenueToday) ? busiestVenueStats?.busiestVenueToday : 'No Record',
      progressTitle: 'Today',
      // path: '/dashboard/venues'
    },

  ])

  useLayoutEffect(() => {
    dispatch(getAnalytics())
  }, [])

  return (
    <section >
      <PageHeader label={'Dashboard Analytics'} />
      <div className='cardContainer'>
        <Row gutter={[16, 16]}>
          {cardData.map((data, index) => (
            <Col md={12} lg={8} xl={6} key={index}><Card {...data} /></Col>
          ))}
        </Row>
      </div>
    </section>
  )
}
