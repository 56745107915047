import React from 'react'
import './tableStyle.scss'

export default function TableWrapper({children}) {
  return (
    <div className='table-wrapper'>
      {children}
    </div>
  )
}
