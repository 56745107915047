import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from '../../components/table/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { getVenuesList } from '../../redux/venues/actionCreator';
import PageHeader from '../../components/page-header/PageHeader';
import {
    CalendarOutlined,
    EnvironmentOutlined,
    FilterOutlined,
} from '@ant-design/icons';
import TableWrapper from '../../components/table/TableWrapper';
import { Col, Dropdown, Input, Row, Button, DatePicker } from 'antd';
import SearchInput from '../../components/search-input/SearchInput';
import TableHeader from '../../components/table/TableHeader';
import FieldInput from '../../components/field-input/FieldInput';
import moment from 'moment';
import { useNavigate } from 'react-router';
import TablePagination from '../../components/table/TablePagination';
import Loader from '../../components/loader/Loader';

import SelectInput from '../../components/select-input/SelectInput';
import './Venues.scss';
import InputDate from '../../components/datepicker/InputDate';

export default function Venues() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, venues, total } = useSelector((_state) => ({
        venues: _state.venues.venues,
        isLoading: _state.venues.loading,
        total: _state.venues.total,
    }));
    const [resetPagination, setResetPagination] = useState(null);
    const [startCount, setStartCount] = useState(1);
    const [filter, setFilter] = useState({
        name: '',
        createdAt: '',
        sort: 'desc',
        address: '',
    });

    const updateChildState = (newState) => {
        setResetPagination(newState);
    };

    const handleFilterButtonClick = () => {
        // console.log("filter btn clicked")
        getVenuesLists(1, 10);
        updateChildState(1);
    };

    const columns = [
        {
            title: 'Sr#',
            dataIndex: 'srno',
        },
        {
            title: 'Venue Name',
            dataIndex: 'venueName',
        },
        {
            title: 'Registration Date and Time',
            dataIndex: 'registrationDateAndTime',
        },
        {
            title: 'Location',
            dataIndex: 'location',
        },
        {
            title: 'Account Duration',
            dataIndex: 'accountDuration',
        },
        {
            title: 'Revenue Generated',
            dataIndex: 'revenueGenerated',
        },
        {
            title: 'Action',
            dataIndex: 'action',
        },
    ];

    const items = useCallback(
        (id, payload) => [
            {
                key: '1',

                label: (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`/dashboard/venues/${id}`, {
                                state: {
                                    payload,
                                },
                            });
                        }}
                    >
                        View
                    </a>
                ),
            },
        ],
        [],
    );

    const onFilterHandler = (event) => {
        const { name, value } = event.target;
        setFilter((previousFilter) => ({ ...previousFilter, [name]: value }));
    };

    const onFilterDateHandler = (date) => {
        setFilter((previousFilter) => ({ ...previousFilter, createdAt: date }));
    };

    const getVenuesLists = useCallback(
        (page, pageSize) => {
            const startIndex = (page - 1) * pageSize;
            let filterStr = '';
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key)) {
                    if (filter[key] !== '')
                        filterStr += `&${key}=${filter[key]}`;
                }
            }

            setStartCount(() => {
                dispatch(getVenuesList(page, pageSize, filterStr));
                return startIndex;
            });
        },
        [filter.name, filter.address, filter.createdAt, filter.sort],
    );

    useEffect(
        () => {
            getVenuesLists(1, 10);
        },
        [
            // filter
            //  getVenuesLists
        ],
    );

    return (
        <div>
            <PageHeader label={'Registered Venues'} />
            <TableWrapper>
                <Row justify="space-between">
                    <Col span={6}>
                        <SearchInput
                            value={filter.name}
                            name="name"
                            onChange={onFilterHandler}
                        />
                    </Col>
                    <Col span={4}>
                        <FieldInput
                            key={1}
                            label={'Location'}
                            name={'address'}
                            onChange={onFilterHandler}
                            labelSize={false}
                            placeholder={'Choose location'}
                            value={filter.address}
                            suffix={
                                <EnvironmentOutlined
                                    style={{ color: '#6C757D' }}
                                />
                            }
                        />
                    </Col>
                    <Col span={4}>
                        <InputDate
                            label={'Date Registered'}
                            labelSize={false}
                            name={'createdAt'}
                            type="date"
                            value={filter.createdAt}
                            placeholder={'Choose data and time'}
                            onChange={onFilterDateHandler}
                            className="custom-date-input"
                            color={'white'}
                        />
                    </Col>

                    <Col span={4}>
                        <SelectInput
                            labelSize={false}
                            placeholder={'Highest to lowest'}
                            label={'Revenue Generated'}
                            value={filter.sort}
                            handleChange={(value) =>
                                onFilterHandler({
                                    target: {
                                        name: 'sort',
                                        value: value,
                                    },
                                })
                            }
                            name="sort"
                            options={[
                                {
                                    label: 'Highest to lowest',
                                    key: 1,
                                    value: 'desc',
                                },
                                {
                                    label: 'Lowest to highest',
                                    key: 2,
                                    value: 'asc',
                                },
                            ]}
                        />
                    </Col>
                    <Col span={4}>
                        <Button
                            disabled={isLoading}
                            icon={<FilterOutlined />}
                            className="filterBtn"
                            onClick={() => handleFilterButtonClick()}
                        >
                            Filter
                        </Button>
                    </Col>
                </Row>
                <br />
                <TableHeader
                    title={'The number of venues registered with NEVERLEFT'}
                    status={total}
                />
                {isLoading ?
                    <>
                        <DataTable
                            columns={columns}
                            loading={isLoading}
                            callback={
                                Array.isArray(venues) &&
                                venues?.length > 0 &&
                                venues?.map((row, index) => (
                                    <tr key={index} className="table-row">
                                        <td className="table-data">
                                            {index + 1 + startCount}
                                        </td>
                                        <td className="table-data">
                                            {row?.name ?? '--'}
                                        </td>
                                        <td className="table-data">

                                            <span>
                                                {moment(row?.createdAt).format(
                                                    'YYYY-MM-DD',
                                                )}{' '}
                                            </span>
                                            <span style={{ fontWeight: 'bold' }} >
                                                {' '}
                                                {moment(row?.createdAt).format(
                                                    'hh:mm A',
                                                )}
                                            </span>
                                            {/* // moment(row?.createdAt).format('YYYY-MM-DD
                                    hh:mm:ss') ?? '--' */}
                                        </td>
                                        <td className="table-data">
                                            {row?.address ?? '--'}
                                        </td>
                                        <td className="table-data">
                                            {moment(row?.createdAt).fromNow() ?? '--'}
                                        </td>
                                        <td className="table-data">
                                            {row?.total_revenue ?? '--'}
                                        </td>
                                        <td className="table-data">
                                            <Dropdown
                                                menu={{
                                                    items: items(row?._id, row),
                                                }}
                                                trigger={['click']}
                                            // open={selectedRowKey === index}
                                            // onOpenChange={(visible) =>
                                            //     setSelectedRowKey(
                                            //         visible ? index : null,
                                            //     )
                                            // }
                                            >
                                                <a
                                                    className="ant-dropdown-link"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img src="/assets/images/Union.svg" />
                                                </a>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))
                            }
                        />
                        <TablePagination
                            total={total}
                            onChange={(_page, _pageSize) => {
                                getVenuesLists(_page, _pageSize);
                            }}
                            isLoading={isLoading}
                            resetPaginationIndex={resetPagination}
                            setResetPagination={setResetPagination}
                        />
                    </>
                    :
                    venues?.length > 0 ? <>
                        <DataTable
                            columns={columns}
                            loading={isLoading}
                            callback={
                                Array.isArray(venues) &&
                                venues?.length > 0 &&
                                venues?.map((row, index) => (
                                    <tr key={index} className="table-row">
                                        <td className="table-data">
                                            {index + 1 + startCount}
                                        </td>
                                        <td className="table-data">
                                            {row?.name ?? '--'}
                                        </td>
                                        <td className="table-data">

                                            <span>
                                                {moment(row?.createdAt).format(
                                                    'YYYY-MM-DD',
                                                )}{' '}
                                            </span>
                                            <span style={{ fontWeight: 'bold' }} >
                                                {' '}
                                                {moment(row?.createdAt).format(
                                                    'hh:mm A',
                                                )}
                                            </span>
                                            {/* // moment(row?.createdAt).format('YYYY-MM-DD
                                    hh:mm:ss') ?? '--' */}
                                        </td>
                                        <td className="table-data">
                                            {row?.address ?? '--'}
                                        </td>
                                        <td className="table-data">
                                            {moment(row?.createdAt).fromNow() ?? '--'}
                                        </td>
                                        <td className="table-data">
                                            {row?.total_revenue ?? '--'}
                                        </td>
                                        <td className="table-data">
                                            <Dropdown
                                                menu={{
                                                    items: items(row?._id, row),
                                                }}
                                                trigger={['click']}
                                            // open={selectedRowKey === index}
                                            // onOpenChange={(visible) =>
                                            //     setSelectedRowKey(
                                            //         visible ? index : null,
                                            //     )
                                            // }
                                            >
                                                <a
                                                    className="ant-dropdown-link"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img src="/assets/images/Union.svg" />
                                                </a>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))
                            }
                        />
                        <TablePagination
                            total={total}
                            onChange={(_page, _pageSize) => {
                                getVenuesLists(_page, _pageSize);
                            }}

                            isLoading={isLoading}
                            resetPagination={resetPagination}
                            setResetPagination={setResetPagination}
                        />
                    </> :
                        <div>No Venues Found.</div>
                }
            </TableWrapper>
        </div>
    );
}
