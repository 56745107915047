const actions = {
    ERROR: 'ERROR',
    BEGIN: 'BEGIN',
    GET_USER_MANAGMENT: 'GET_USER_MANAGMENT',

    begin: () => {
        return {
            type: actions.BEGIN,
        };
    },

    error: (err) => {
        return {
            type: actions.ERROR,
            err,
        };
    },

    getUsers: (data, total) => {
        return {
            type: actions.GET_USER_MANAGMENT,
            data,
            total,
        };
    },
};

export default actions;
