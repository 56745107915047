import { SAVE_CLUB_ID } from "../context-actions";

export const ClubId = (state, action) => {
  switch (action.type) {
    case SAVE_CLUB_ID:
      return { ...state, clubId: action.payload };

    default:
      return state;
  }
};
