import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import insttutionImg from "../../../assets/images/colorInstitution.svg";
import clubImg from "../../../assets/images/coloredClubs.svg";
import dataQualityImg from "../../../assets/images/Data Quality.svg";

import "./Home.scss";
import BaseLayout from "../Layout";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <BaseLayout />
    </>
  );
};

export default Home;
