import React, { useEffect, useState } from "react";
import { Tooltip, PieChart, Pie, Cell, Legend } from "recharts";
import { Modal, Spin, Button, Select, Row, Col } from "antd";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getApiWithAuth } from "../../../utils/api";
import { Url } from "../../../utils/apiUrl";
import "react-datepicker/dist/react-datepicker.css";
import ClubUserAnalytics from "./ClubUserAnalytics";
import "./Analytics.scss";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";

export default function ClubAnalytics() {
  ChartJS.register(...registerables);
  const [clubs, setClubs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedClub, setSelectedClub] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState(
    moment(startDate).format("YYYY-MM-DD")
  );
  const [selectedButton, setSelectedButton] = useState("date");
  const [selectedToggleButton, setSelectedToggleButton] =
    useState("statistics");
  const [hangerCount, setHangerCount] = useState([]);
  const [isLoading, setIsLoading] = useState({
    fetchClubLoader: false,
    chartLoader: false,
  });
  const [studentCount, setStudentCount] = useState({});
  const [pieData, setPieData] = useState([]);
  const [ticketCount, setTicketCount] = useState([]);
  const [ticketType, setTicketType] = useState("all");
  const [coatCount, setCoatCount] = useState([]);
  const [leftCoatCount, setLeftCoatCount] = useState(0);

  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const handleButtonFilterClick = (type, format) => {
    setSelectedButton(type);
    setFormattedDate(moment(startDate).format(format));
  };

  const handleButtonGoClick = () => {
    if (Object.keys(selectedClub).length > 0) {
      if (selectedToggleButton == "hanger") getHangerCount();
      else if (selectedToggleButton == "ticket") {
        if (ticketType == "all") getTicketCount();
        else getTypeTicketCount();
      } else if (selectedToggleButton == "Coat") getCoatCount();
    }
  };

  const getPieData = (data) => {
    const pieData = [];

    for (const key in data) {
      if (key != "Total")
        pieData.push({
          name: key,
          value: data[key],
        });
    }
    return pieData;
  };

  const selectHandleChange = (event) => {
    setTicketCount([]);
    setTicketType(event.target.value);
  };

  const calcPercentage = (value, total) => {
    const percentage = (value / total) * 100;
    return percentage.toFixed(2) + "%";
  };

  const getAllClubs = async () => {
    setIsLoading({ ...isLoading, fetchClubLoader: true });
    const response = await getApiWithAuth(Url.clubsUrl);
    if (response.success) {
      setClubs(response.data);
    }
    setIsLoading({ ...isLoading, fetchClubLoader: false });
  };

  const getTypeTicketCount = async () => {
    setIsLoading({ ...isLoading, chartLoader: true });
    const response = await getApiWithAuth(
      `${Url.ticketCountType}${selectedButton}=${formattedDate}&usertype=${ticketType}&id=${selectedClub._id}`
    );
    if (response.success) {
      if (selectedButton === "year") {
        setTicketCount(getTicketMonthCount(response.data));
      } else if (selectedButton === "month") {
        setTicketCount(getTicketDayCount(response.data));
      } else setTicketCount(getTicketHourCount(response.data));
    }
    setIsLoading({ ...isLoading, chartLoader: false });
  };

  const getTicketCount = async () => {
    setIsLoading({ ...isLoading, chartLoader: true });
    const response = await getApiWithAuth(
      `${Url.ticketCount}${selectedButton}=${formattedDate}&id=${selectedClub._id}`
    );
    if (response.success) {
      if (selectedButton === "year") {
        setTicketCount(getTicketMonthCount(response.data));
      } else if (selectedButton === "month") {
        setTicketCount(getTicketDayCount(response.data));
      } else setTicketCount(getTicketHourCount(response.data));
    }
    setIsLoading({ ...isLoading, chartLoader: false });
  };

  const getHangerCount = async () => {
    setIsLoading({ ...isLoading, chartLoader: true });
    const response = await getApiWithAuth(
      `${Url.hangerCount}?${selectedButton}=${formattedDate}&club_id=${selectedClub._id}`
    );
    if (response.success) {
      if (selectedButton === "year") {
        setHangerCount(getHangerCoatMonthCount(response.data));
      } else if (selectedButton === "month")
        setHangerCount(getTicketDayCount(response.data));
      else {
        setHangerCount(response.data);
      }
    }
    setIsLoading({ ...isLoading, chartLoader: false });
  };

  const getUserCount = async () => {
    setIsLoading({ ...isLoading, chartLoader: true });
    const response = await getApiWithAuth(
      `${Url.userCounter}${selectedClub._id}`
    );
    if (response.success) {
      setStudentCount(response.data);
      setPieData(getPieData(response.data));
    }
    setIsLoading({ ...isLoading, chartLoader: false });
  };

  const getCoatCount = async () => {
    setIsLoading({ ...isLoading, chartLoader: true });
    const response = await getApiWithAuth(
      `${Url.coatCounter}${selectedClub._id}&${selectedButton}=${formattedDate}`
    );
    if (response.success) {
      if (selectedButton === "year") {
        setCoatCount(getHangerCoatMonthCount(response.data));
      } else {
        setCoatCount(response.data);
      }
    }
    getLeftCoatCount();
    setIsLoading({ ...isLoading, chartLoader: false });
  };

  const getLeftCoatCount = async () => {
    const response = await getApiWithAuth(`${Url.leftCoat}${selectedClub._id}`);
    if (response.success) {
      setLeftCoatCount(response.data?.count);
    } else setLeftCoatCount(0);
  };

  const getHangerCoatMonthCount = (data) => {
    const result = [];
    data.forEach((item) => {
      let count = 0;
      item.days.forEach((day) => {
        count += day.count;
      });
      result.push({
        _id: item.month,
        count: count,
      });
    });
    return result;
  };

  const getTicketMonthCount = (data) => {
    const result = [];
    data.forEach((item) => {
      let count = 0;
      item.days.forEach((day) => {
        if (ticketType == "Select Student" || ticketType == "all") {
          count += day.Studentcount;
          count += day.UserCount;
        } else if (ticketType == "student") {
          count += day.student;
        } else count += day.normalUser;
      });
      result.push({
        _id: item.month,
        count: count,
      });
    });
    return result;
  };

  const getTicketDayCount = (data) => {
    let dayCounter = [];
    data.map((day) => {
      let counter = 0;
      if (selectedToggleButton == "ticket") {
        if (ticketType == "Select Student" || ticketType == "all") {
          counter += day.ticket.StudentCount;
          counter += day.ticket.UserCount;
        } else if (ticketType == "normalUser") counter += day.ticket.normalUser;
        else counter += day.ticket.student;
      } else {
        counter += day.count;
      }

      dayCounter.push({
        _id: day._id,
        count: counter,
      });
    });

    return dayCounter;
  };

  const getTicketHourCount = (data) => {
    let hourData = [];
    for (const key in data) {
      let counter = 0;
      data[key].days.map((item) => {
        if (ticketType == "Select Student" || ticketType == "all") {
          counter += item.Studentcount;
          counter += item.UserCount;
        } else if (ticketType == "normalUser") {
          counter += item.normalUser;
        } else counter += item.student;
      });
      hourData.push({ _id: key, count: counter });
    }

    return hourData;
  };

  const showHangerCounterChart = () => {
    let labelArr;
    if (selectedButton == "year") {
      labelArr = hangerCount.map((item) => {
        return moment()
          .month(item._id - 1)
          .format("MMM");
      });
    } else {
      labelArr = hangerCount.map((item) => {
        return item._id;
      });
    }

    let hangerCounter = hangerCount.map((item) => {
      return item.count;
    });

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
      },
    };
    const data = {
      labels: labelArr,
      datasets: [
        {
          label: "Hanger Count",
          data: hangerCounter,
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };
    return (
      <>
        {hangerCount.length > 0 ? (
          <div className="chartDiv">
            <Line data={data} options={options} />
          </div>
        ) : isLoading.chartLoader ? (
          <div className="noDataStyleAnalytics">
            <Spin tip="Loading" size="large" style={{ width: "100%" }} />
          </div>
        ) : (
          <div>
            <p className="noDataStyleAnalytics">NO DATA</p>
          </div>
        )}
      </>
    );
  };

  const showTicketCounterTypeChart = () => {
    let labelArr;
    if (selectedButton == "year") {
      labelArr = ticketCount.map((item) => {
        return moment()
          .month(item._id - 1)
          .format("MMM");
      });
    } else {
      labelArr = ticketCount.map((item) => {
        return item._id;
      });
    }

    let ticketCounter = ticketCount.map((item) => {
      return item.count;
    });

    let userType = ticketType;

    if (userType == "normalUser") userType = "normal user";

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
      },
    };

    const data = {
      labels: labelArr,
      datasets: [
        {
          label: `${userType.toUpperCase()} TICKET COUNT`,
          data: ticketCounter,
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };

    return (
      <>
        {ticketCount.length > 0 && ticketType != "Select Student" ? (
          <div className="chartDiv">
            <Line data={data} options={options} />
          </div>
        ) : isLoading.chartLoader ? (
          <div className="noDataStyleAnalytics">
            <Spin tip="Loading" size="large" style={{ width: "100%" }} />
          </div>
        ) : (
          <div>
            <p className="noDataStyleAnalytics">NO DATA</p>
          </div>
        )}
      </>
    );
  };

  const showCoatCounterChart = () => {
    let labelArr;
    if (selectedButton == "year") {
      labelArr = coatCount.map((item) => {
        return moment()
          .month(item._id - 1)
          .format("MMM");
      });
    } else {
      labelArr = coatCount.map((item) => {
        return item._id;
      });
    }

    let coatCounter = coatCount.map((item) => {
      return item.count;
    });

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
      },
    };

    const data = {
      labels: labelArr,
      datasets: [
        {
          label: "Collected Coats",
          data: coatCounter,
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };

    return (
      <>
        <div className="analyticsUserCount">
          <span>Left Coat: </span>
          <span
            style={{
              backgroundColor: "black",
              color: "#5ce1e6",
              padding: 3,
              borderRadius: "0.375rem",
              marginRight: 2,
            }}
          >
            {isLoading.chartLoader ? (
              <Spin size="small" style={{ padding: 5 }} />
            ) : (
              leftCoatCount
            )}
          </span>
        </div>
        {coatCount.length > 0 ? (
          <div className="chartDiv">
            <Line data={data} options={options} />
          </div>
        ) : isLoading.chartLoader ? (
          <div className="noDataStyleAnalytics">
            <Spin tip="Loading" size="large" style={{ width: "100%" }} />
          </div>
        ) : (
          <div>
            <p className="noDataStyleAnalytics">NO DATA</p>
          </div>
        )}
      </>
    );
  };

  const showUserChart = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "98%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="analyticsUserCount">
          <span> TOTAL COUNT: </span>
          <span
            style={{
              backgroundColor: "black",
              color: "#5ce1e6",
              padding: 3,
              borderRadius: "0.375rem",
              marginRight: 2,
            }}
          >
            {studentCount?.Total ? (
              studentCount.Total
            ) : studentCount?.Total == 0 ? (
              0
            ) : (
              <Spin size="small" style={{ padding: 5 }} />
            )}
          </span>
          <span>TOTAL STUDENTS: </span>
          <span
            style={{
              backgroundColor: "black",
              color: "#5ce1e6",
              padding: 3,
              borderRadius: "0.375rem",
            }}
          >
            {studentCount?.student ? (
              studentCount.student
            ) : studentCount?.student == 0 ? (
              0
            ) : (
              <Spin size="small" style={{ padding: 5 }} />
            )}
          </span>
          <span> TOTAL NORMAL USERS: </span>
          <span
            style={{
              backgroundColor: "black",
              color: "#5ce1e6",
              padding: 3,
              borderRadius: "0.375rem",
            }}
          >
            {studentCount["Normal User"] ? (
              studentCount["Normal User"]
            ) : studentCount["Normal User"] == 0 ? (
              0
            ) : (
              <Spin size="small" style={{ padding: 5 }} />
            )}
          </span>
        </div>
        {studentCount?.Total > 0 ? (
          <div className="pieChartContainer">
            <PieChart width={400} height={364} margin={{ bottom: 30 }}>
              <Pie
                data={pieData}
                cx={200}
                cy={170}
                innerRadius={70}
                fill="#8884d8"
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value) =>
                  calcPercentage(
                    value,
                    pieData.reduce((acc, cur) => acc + cur.value, 0)
                  )
                }
              />
              <Legend />
            </PieChart>
          </div>
        ) : (
          <p className="noDataStyle">NO DATA</p>
        )}
      </div>
    );
  };

  const showLineChartFilters = () => {
    return (
      <div>
        <div
          className={
            selectedToggleButton === "ticket"
              ? "modalTopContainer1"
              : "modalTopContainer"
          }
        >
          <div className="timeButtonContainer">
            <Button
              type="primary"
              style={{
                backgroundColor: "#5ce1e6",
                border: " 1px solid #5ce1e6",
              }}
              onClick={() => handleButtonFilterClick("date", "YYYY-MM-DD")}
              className={
                selectedButton === "date"
                  ? "btnStyle selected-button"
                  : "btnStyle"
              }
            >
              Date
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: "#5ce1e6",
                border: " 1px solid #5ce1e6",
              }}
              onClick={() => handleButtonFilterClick("month", "YYYY-MM")}
              className={
                selectedButton === "month"
                  ? "btnStyle selected-button"
                  : "btnStyle"
              }
            >
              Month
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: "#5ce1e6",
                border: " 1px solid #5ce1e6",
              }}
              onClick={() => handleButtonFilterClick("year", "YYYY")}
              className={
                selectedButton === "year"
                  ? "btnStyle selected-button"
                  : "btnStyle"
              }
            >
              Year
            </Button>
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: selectedToggleButton == "ticket" ? "8px" : "15px",
            }}
          >
            {selectedButton === "date" ? (
              <DatePicker
                maxDate={new Date()}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy-MM-dd"
              />
            ) : selectedButton === "month" ? (
              <DatePicker
                maxDate={new Date()}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy-MM"
                showMonthYearPicker
              />
            ) : (
              <DatePicker
                maxDate={new Date()}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy"
                showYearPicker
              />
            )}
          </div>
          {selectedToggleButton === "ticket" ? (
            <div>
              <select
                placeholder="Select Student"
                className="selectOptionStudent"
                onChange={(event) => selectHandleChange(event)}
              >
                <option value="all">All</option>
                <option value="student">Student</option>
                <option value="normalUser">Normal User</option>
              </select>
            </div>
          ) : (
            ""
          )}

          <Button
            type="primary"
            className="btnStyle"
            style={{
              backgroundColor: "#5ce1e6",
              border: " 1px solid #5ce1e6",
              width: "25%",
            }}
            onClick={handleButtonGoClick}
          >
            Go
          </Button>
        </div>
        {selectedToggleButton == "ticket"
          ? showTicketCounterTypeChart()
          : selectedToggleButton == "hanger"
          ? showHangerCounterChart()
          : showCoatCounterChart()}
      </div>
    );
  };

  const showStatisticsTab = () => {
    return <ClubUserAnalytics clubId={selectedClub._id} />;
  };

  useEffect(() => {
    getAllClubs();
  }, []);

  useEffect(() => {
    if (selectedButton === "date") {
      setFormattedDate(moment(startDate).format("YYYY-MM-DD"));
    } else if (selectedButton === "month") {
      setFormattedDate(moment(startDate).format("YYYY-MM"));
    } else {
      setFormattedDate(moment(startDate).format("YYYY"));
    }
  }, [startDate]);

  useEffect(() => {
    if (selectedToggleButton == "User") {
      getUserCount();
    }
  }, [selectedToggleButton]);

  useEffect(() => {
    setSelectedToggleButton("statistics");
    setStudentCount({});
    setTicketCount([]);
    setHangerCount([]);
    setTicketType("all");
    setCoatCount([]);
    setLeftCoatCount(0);
    setIsLoading({ ...isLoading, chartLoader: false });
  }, [showModal]);

  return (
    <div className="analyticsContainer">
      <div
        className="homePageOuterContainer analyticsW100"
        style={{ paddingBottom: "10px" }}
      >
        <div className="homepageHeading clubPageHeading">
          <h1>CLUB ANALYTICS</h1>
        </div>
        {isLoading.fetchClubLoader ? (
          <Spin tip="Loading" size="large" />
        ) : (
          <Row
            gutter={[16, 20]}
            style={{ marginTop: "5%", marginBottom: "2% !important" }}
            className="clubAnalyticsContainer"
          >
            {clubs.map((item) => (
              <Col lg={6} md={8}>
                <div
                  className="clubCardMargin"
                  onClick={() => {
                    setSelectedClub(item);
                    setShowModal(true);
                  }}
                >
                  <div className="clubcard1">
                    <div className="clubcardInner">
                      <div>
                        <h1 className="clubinstitution">{item.name}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )}
        <Modal
          width={800}
          title={selectedClub.name}
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={[
            <div className="toggleSwitchContainer">
              <button
                className={
                  selectedToggleButton === "statistics"
                    ? "toggleButton toggleButtonSelected"
                    : "toggleButton"
                }
                onClick={() => {
                  setSelectedToggleButton("statistics");
                }}
              >
                Customer's Usage
              </button>
              <button
                className={
                  selectedToggleButton === "Coat"
                    ? "toggleButton toggleButtonSelected"
                    : "toggleButton"
                }
                onClick={() => {
                  setSelectedToggleButton("Coat");
                }}
              >
                Collected Coats
              </button>
              <button
                className={
                  selectedToggleButton === "User"
                    ? "toggleButton toggleButtonSelected"
                    : "toggleButton"
                }
                onClick={() => {
                  setSelectedToggleButton("User");
                }}
              >
                User Count
              </button>
              <button
                className={
                  selectedToggleButton === "ticket"
                    ? "toggleButton toggleButtonSelected"
                    : "toggleButton"
                }
                onClick={() => {
                  setSelectedToggleButton("ticket");
                }}
              >
                Ticket Count
              </button>
              <button
                className={
                  selectedToggleButton === "hanger"
                    ? "toggleButton toggleButtonSelected"
                    : "toggleButton"
                }
                onClick={() => {
                  setSelectedToggleButton("hanger");
                }}
              >
                Hanger Count
              </button>
            </div>,
          ]}
        >
          {selectedToggleButton == "User"
            ? showUserChart()
            : selectedToggleButton == "statistics"
            ? showStatisticsTab()
            : showLineChartFilters()}
        </Modal>
      </div>
    </div>
  );
}
