import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import { getApiWithAuth } from "../../../utils/api";
import { Url } from "../../../utils/apiUrl";
import custImg from "../../../assets/images/userImage.jpg";
import logo from "../../../assets/images/google.svg";
import QrCode from "./QrCode";
import "./StudentInformation.scss";

const StudentInformation = () => {
  let [searchParams] = useSearchParams();

  const [isLoading, setisLoading] = useState(false);
  const [studentInfo, setStudentInfo] = useState([]);
  const [color, setColor] = useState("");
  const [isLogo, setIsLogo] = useState("");
  const [avatar, setAvatar] = useState("");
  const getAllStudenInfo = async () => {
    setisLoading(true);

    let student_id = searchParams.get("id");
    let qrCode = searchParams.get("qrIdentifier");
    const response = await getApiWithAuth(
      `${Url.studentInfo}${student_id}&qrIdentifier=${qrCode}`
    );
    if (response.success) {
      setColor(
        response.data.data[response.data.data.length - 1].backgroundColor
      );
      setIsLogo(response.data?.logoUrl.logo);
      setStudentInfo(response.data.data);
      setisLoading(false);
      setAvatar(response.data?.Image?.Image);
    } else {
      setisLoading(false);
    }
  };

  useEffect(() => {
    getAllStudenInfo();
  }, []);

  const studentDetails = () => {
    return (
      <div className="studenInfoContainer">
        <div className="studentInfoImgdiv">
          <img
            src={avatar != "" ? avatar : custImg}
            style={{ width: "100px", height: "100px", borderRadius: "15%" }}
          />
        </div>
        <div className="studentInfoContent" style={{ backgroundColor: color }}>
          <div
            style={{ backgroundColor: color }}
            className="studenOuterCrdParent"
          >
            <div className="studenOuterCrd">
              <div className="studentCard">
                <div className="studentDataContainer">
                  <div className="studentStyling">
                    {studentInfo
                      .filter((dataItem) => typeof dataItem.key != "undefined")
                      .map((item) => {
                        return (
                          <div className="studentData" key={item.key}>
                            <div className="studentName">
                              <div className="studentApiName">{item.label}</div>
                            </div>
                            <div className="studentValue">
                              <div className="studntApiData">
                                {item.dummyData}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="studetLogoContainer">
                <div className="universityLOgo">
                  <img src={isLogo != "" ? isLogo : logo} />
                </div>
                <div className="scanner">
                  <QrCode />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="studentInfoContainer">
      {isLoading ? (
        <div className="studentSpiner">
          <Spin size="large" />
        </div>
      ) : studentInfo.length == 0 ? (
        <div className="noStudentInfoContainer">
          <div> Invalid QR Code</div>
        </div>
      ) : (
        studentDetails()
      )}
    </div>
  );
};

export default StudentInformation;
