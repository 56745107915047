import React, { useState, useEffect } from "react";
import { Button, Spin } from "antd";
import moment from "moment";
import DatePicker from "react-datepicker";
import "./Analytics.scss";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { getApiWithAuth } from "../../../utils/api";
import { Url } from "../../../utils/apiUrl";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";

const ApplicationAnalytics = () => {
  ChartJS.register(...registerables);
  const [startDate, setStartDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState(
    moment(startDate).format("YYYY-MM-DD")
  );
  const [selectedButton, setSelectedButton] = useState("date");
  const [isLoading, setIsLoading] = useState({
    fetchClubLoader: false,
    chartLoader: false,
  });
  const [chartData, setChartData] = useState([]);

  const handleButtonFilterClick = (type, format) => {
    setSelectedButton(type);
    setFormattedDate(moment(startDate).format(format));
  };

  const handleButtonGoClick = async () => {
    setIsLoading({ ...isLoading, chartLoader: true });
    const response = await getApiWithAuth(
      `${Url.appUsageRecords}${selectedButton}=${formattedDate}`
    );
    if (response.success) {
      if (selectedButton == "year")
        setChartData(getCountMonthData(response.data));
      else if (selectedButton == "month")
        setChartData(getCountDayHourData(response.data));
      else setChartData(getCountDayHourData(response.data));
    }
    setIsLoading({ ...isLoading, chartLoader: false });
  };

  const getCountMonthData = (data) => {
    let dataArray = [];
    let dateArray = [];
    for (const key in data) {
      const datakey = data[key];
      for (const nestdataKey in datakey) {
        dateArray.push(nestdataKey);
      }
    }
    dateArray = [...new Set(dateArray)];

    dateArray.map((singleDate) => {
      let userCount = 0;
      let normalCount = 0;
      for (const key in data) {
        const datakey = data[key];
        for (const nestdataKey in datakey) {
          datakey[nestdataKey].days.map((item) => {
            if (datakey[nestdataKey].month == singleDate) {
              if (key == "normalUsers") normalCount = normalCount + item.count;
              else userCount = userCount + item.count;
            }
          });
        }
      }
      dataArray.push({
        id: singleDate,
        normalUsers: normalCount,
        students: userCount,
      });
    });
    return dataArray;
  };

  const getCountDayHourData = (data) => {
    let dataArray = [];
    let dateArray = [];
    for (const key in data) {
      const datakey = data[key];
      datakey.map((item) => {
        dateArray.push(item._id);
      });
    }
    dateArray = [...new Set(dateArray)];
    dateArray.map((singleDate) => {
      let userCount = 0;
      let normalCount = 0;

      for (const key in data) {
        const datakey = data[key];
        datakey.map((item) => {
          if (item._id == singleDate) {
            if (key == "normalUsers") normalCount = item.count;
            else userCount = item.count;
          }
        });
      }
      dataArray.push({
        id: singleDate,
        normalUsers: normalCount,
        students: userCount,
      });
    });
    dataArray.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    return dataArray;
  };

  const showStudentChart = () => {
    let labelArr;
    if (selectedButton == "year") {
      labelArr = chartData.map((item) => {
        return moment()
          .month(item.id - 1)
          .format("MMM");
      });
    } else {
      labelArr = chartData.map((item) => {
        return item.id;
      });
    }

    let normalUserCount = chartData.map((item) => {
      return item.normalUsers;
    });

    let studentUserCount = chartData.map((item) => {
      return item.students;
    });

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
      },
    };

    const data = {
      labels: labelArr,
      datasets: [
        {
          label: "Normal User",
          data: normalUserCount,
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
        {
          label: "Student User",
          data: studentUserCount,
          fill: false,
          borderColor: "#742774",
        },
      ],
    };

    return (
      <>
        {chartData.length > 0 ? (
          <div className="chartDiv">
            <Line data={data} options={options} />
          </div>
        ) : isLoading.chartLoader ? (
          <div className="noDataStyleAnalytics">
            <Spin tip="Loading" size="large" style={{ width: "100%" }} />
          </div>
        ) : (
          <div>
            <p className="noDataStyleAnalytics">NO DATA</p>
          </div>
        )}
      </>
    );
  };

  const showFilters = () => {
    return (
      <div>
        <div className="applicationContainer">
          <div style={{ display: "flex" }}>
            <div>
              <Button
                style={{
                  marginRight: "15px",
                  backgroundColor: "#5ce1e6",
                  border: " 1px solid #5ce1e6",
                }}
                type="primary"
                onClick={() => handleButtonFilterClick("date", "YYYY-MM-DD")}
                className={
                  selectedButton === "date"
                    ? "btnStyle selected-button"
                    : "btnStyle"
                }
              >
                Date
              </Button>
              <Button
                style={{
                  marginRight: "15px",
                  backgroundColor: "#5ce1e6",
                  border: " 1px solid #5ce1e6",
                }}
                type="primary"
                onClick={() => handleButtonFilterClick("month", "YYYY-MM")}
                className={
                  selectedButton === "month"
                    ? "btnStyle selected-button"
                    : "btnStyle"
                }
              >
                Month
              </Button>
              <Button
                style={{
                  marginRight: "15px",
                  backgroundColor: "#5ce1e6",
                  border: " 1px solid #5ce1e6",
                }}
                type="primary"
                onClick={() => handleButtonFilterClick("year", "YYYY")}
                className={
                  selectedButton === "year"
                    ? "btnStyle selected-button"
                    : "btnStyle"
                }
              >
                Year
              </Button>
            </div>
            <div>
              {selectedButton === "date" ? (
                <DatePicker
                  style={{ color: "black" }}
                  maxDate={new Date()}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  dateFormat="yyyy-MM-dd"
                />
              ) : selectedButton === "month" ? (
                <DatePicker
                  maxDate={new Date()}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  dateFormat="yyyy-MM"
                  showMonthYearPicker
                />
              ) : (
                <DatePicker
                  maxDate={new Date()}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  dateFormat="yyyy"
                  showYearPicker
                />
              )}
            </div>
          </div>
          <div className="applicationGoContainer">
            <Button
              type="primary"
              className="btnStyle"
              style={{
                width: "50%",
                backgroundColor: "#5ce1e6",
                border: " 1px solid #5ce1e6",
              }}
              onClick={handleButtonGoClick}
            >
              Go
            </Button>
          </div>
        </div>
        {showStudentChart()}
      </div>
    );
  };

  useEffect(() => {
    if (selectedButton === "date") {
      setFormattedDate(moment(startDate).format("YYYY-MM-DD"));
    } else if (selectedButton === "month") {
      setFormattedDate(moment(startDate).format("YYYY-MM"));
    } else {
      setFormattedDate(moment(startDate).format("YYYY"));
    }
  }, [startDate]);

  return (
    <div className="analyticsContainer">
      <div className="analyticsHomePageOuterContainer analyticsW100">
        <div className="homepageHeading applicationPageHeading">
          <h1>APPLICATION ANALYTICS</h1>
        </div>
        <div className="applicationAnalyticsContainer">{showFilters()}</div>
      </div>
    </div>
  );
};

export default ApplicationAnalytics;
