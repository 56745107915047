import {
    deleteApiWithAuth,
    getApiWithAuth,
    postApiWithAuth,
    putApiWithAuth,
} from '../../utils/api';
import { Url } from '../../utils/apiUrl';
import actions from './actions';

const { begin, error, getUsers } = actions;

const getUserList = (page = 1, pageSize = 10, filters = {}) => {
    return async (dispatch) => {
        try {
            dispatch(begin());
            const { data } = await getApiWithAuth(
                Url.fetchUser + `limit=${pageSize}&skip=${page}${filters}`,
            );

            if (data.success) {
                const { users, totalUserCount } = data.data;
                dispatch(getUsers(users, totalUserCount));
            } else {
                dispatch(error(data?.message));
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const blockUserAccount = (id, status, callback) => {
    return async (dispatch) => {
        try {
            dispatch(begin());
            const { data } = await putApiWithAuth(
                Url.blockUserAccount + `userId=${id}&access=${status}`,
            );

            if (data) {
                callback(data?.Message);
                const { data: userResponse } = await getApiWithAuth(
                    Url.fetchUser + `limit=${10}&skip=${0}&userName=${''}`,
                );

                if (userResponse?.success) {
                    const { users, totalUserCount } = userResponse.data;
                    dispatch(getUsers(users, totalUserCount));
                } else {
                    dispatch(error(data?.message));
                }
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const deleteUserAccount = (id, callback) => {
    return async (dispatch) => {
        try {
            const { data } = await deleteApiWithAuth(
                Url.deleteUserAccount + `?userId=${id}`,
            );

            if (data.success) {
                callback(data?.data, data.success);
                const { data: userResponse } = await getApiWithAuth(
                    Url.fetchUser + `limit=${10}&skip=${0}&userName=${''}`,
                );

                if (userResponse?.success) {
                    const { users, totalUserCount } = userResponse.data;
                    dispatch(getUsers(users, totalUserCount));
                } else {
                    dispatch(error(data?.message));
                }
            } else {
                callback(data?.message, data.success);
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

export { getUserList, blockUserAccount, deleteUserAccount };
