import {
    getApiWithAuth,
    patchApiWithAuth,
    postApiWithAuth,
} from '../../utils/api';
import { Url } from '../../utils/apiUrl';
import actions from './actions';

const { setVenues, setVenueProfile, begin, error } = actions;

const getVenuesList = (page = 1, pageSize = 10, filterStr) => {
    return async (dispatch) => {
        try {
            dispatch(begin());
            const { data } = await getApiWithAuth(
                Url.venuesList +
                    `page=${page}&pageSize=${pageSize}${filterStr}`,
            );

            if (data.success) {
                const { clubList, totalVenues } = data?.data;
                dispatch(setVenues(clubList, totalVenues));
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const setVenueDetail = (id) => {
    return async (dispatch) => {
        try {
            dispatch(begin());
            const { data } = await getApiWithAuth(Url.venuesProfile + id);
            if (data.success) {
                dispatch(setVenueProfile(data.data));
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const venuePauseSubscription = (id, callback) => {
    return async (dispatch) => {
        try {
            const { data } = await patchApiWithAuth(Url.pauseSubscription + id);
            callback(data);
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const clubAccess = (clubId, status, callback) => {
    return async (dispatch) => {
        try {
            const { data } = await postApiWithAuth(Url.clubAccess, {
                clubId,
                status,
            });

            if (data.success) {
                callback(data?.data);
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const addVenueDiscount = (id, values, callback) => {
    return async (dispatch) => {
        try {
            const { data } = await postApiWithAuth(Url.giveDiscount, {
                club_id: id,
                ...values,
            });

            if (data.success) {
            } else {
                callback(data.message, data.success);
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const addVenueFreeTrail = (id, values, callback) => {
    return async (dispatch) => {
        try {
            const { data } = await postApiWithAuth(Url.freeTrail, {
                club_id: id,
                ...values,
            });
            if (data?.success) {
                callback(data?.data, data?.success);
            } else {
                callback(data?.message, data?.success);
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

export {
    getVenuesList,
    setVenueDetail,
    venuePauseSubscription,
    clubAccess,
    addVenueDiscount,
    addVenueFreeTrail,
};
