import React from "react";
import { Col, Row, Typography } from "antd";
import "./Navbar.scss";
import SearchInput from "../../search-input/SearchInput";
import UserMenu from "../../user-menu/UserMenu";
import { Avatar, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const Navbar = () => {

    const { isLoading, userData } = useSelector((_state) =>
    ({
        isLoading: _state?.auth?.loading,
        userData: _state?.auth?.user,
    })
    );
    const { Text } = Typography;
    // functions
    const navbarUsername = (firstName = '', lastName = '') =>
        firstName.slice(0, 1).toUpperCase() +
        lastName.slice(0, 1).toUpperCase();

    return (
        <Row gutter={12} justify={'end'}>
            <Col
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 24,
                    cursor: 'pointer'
                }}
            >
                <img src="/assets/images/Notification.svg" />
                <Space direction="horizontal">
                    `<Avatar
                        alt="umer mushtaq"
                        style={{
                            backgroundColor: '#5D3A96',
                            height: '48px', width: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        {navbarUsername(userData?.firstName, userData?.lastName)}
                    </Avatar>

                    <Text
                        style={{
                            color: '#fff',
                            fontWeight: 'lighter',
                            textTransform: 'capitalize',
                            fontSize: 18
                        }}
                    >
                        {userData?.firstName}
                    </Text>
                    <Text
                        style={{
                            color: '#fff',
                            fontSize: 18,
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                        }}
                    >
                        {userData?.lastName} |
                    </Text>
                    <Text
                        style={{
                            fontSize: 11,
                            color: '#fff',
                            fontWeight: 'lighter',
                            textTransform: 'capitalize',
                        }}
                    >
                        {userData?.usertype}
                    </Text>
                    <UserMenu />
                </Space>
            </Col>
        </Row>
    );
};
export default Navbar;
