import {
    getApiWithAuth,
    postApiWithAuth,
    postApiWithoutAuth,
} from '../../utils/api';
import { Url } from '../../utils/apiUrl';
import actions from './actions';

const { setDashboard, begin, error } = actions;

const getAnalytics = () => {
    return async (dispatch) => {
        try {
            dispatch(begin());
            const { data } = await getApiWithAuth(Url.analytic);
            if (data?.success) {
                dispatch(setDashboard(data?.data));
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

export { getAnalytics };
