import React from "react";
import "./tableStyle.scss";
import Loader from '../loader/Loader';

export default function DataTable({ columns, callback, loading = true }) {
    return (
        <div className="table-body">
            {loading ? <Loader /> : <table className="table-tag">
                <thead className="table-head">
                    <tr className="table-row">
                        {Array.isArray(columns) &&
                            columns?.map((col, index) => (
                                <th className="table-heading" key={index}>
                                    {col?.title}
                                    {' '}
                                    {col?.sort
                                        // && <img src="/assets/images/sortup.svg" alt="sort" height='10px' />
                                    }
                                    {/* {col !== "Action" && <img className="sorting-icon" src="/images/sortArrowIcon.png" />} */}
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody className="table-body-tag">
                    {callback}
                </tbody>
            </table>}
        </div>
    );
}
