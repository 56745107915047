import {
    getApiWithAuth,
    postApiWithAuth,
    postApiWithoutAuth,
} from '../../utils/api';
import { Url } from '../../utils/apiUrl';
import actions from './actions';

const { setUser, begin, error, logout } = actions;

const addUserPayload = (callback) => {
    return async (dispatch) => {
        try {
            dispatch(begin());
            const { data, status } = await getApiWithAuth(Url.loggedUser);
            if (data?.success) {
                dispatch(setUser(data?.data?.response));
            } else {
                if (status === 401) {
                    if (typeof callback === 'function') callback();
                }
            }
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const sendEmailOtp = (payload) => {
    return async (dispatch) => {
        try {
            // dispatch(begin());
            const { data } = await postApiWithoutAuth(Url.sendOtp, payload);

            // dispatch(setUser(data?.response));
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const emailConfirmation = (payload, callback) => {
    return async (dispatch) => {
        try {
            // dispatch(begin());
            const { data } = await postApiWithoutAuth(
                Url.confirmEmail,
                payload,
            );

            if (data) {
                callback();
            }

            // dispatch(setUser(data?.response));
        } catch (err) {
            console.log(err);
            dispatch(error(err));
        }
    };
};

const logoutUser = () => {
    return async (dispatch) => {
        dispatch(logout());
    };
};

export { addUserPayload, sendEmailOtp, emailConfirmation, logoutUser };
