import React from 'react';
import { Input, Select } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  InboxOutlined,
} from '@ant-design/icons';
import './InputField.scss';

const InputField = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  prefix,
  suffix,
  label,
  addonAfter,
  component,
  error,
  onFocusCapture
}) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  return (
    <>
      {type == "password" ? (
        <div className="inputContainer">
          <p className="labelStyle">{label}</p>
          <Input.Password
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            type="password"
            onFocusCapture={onFocusCapture}
            prefix={prefix ? prefix : null}
            suffix={suffix ? suffix : null}
            iconRender={() =>
              passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
            className="inputStyles"
          />
          {error && <p style={{
            textAlign: 'left',
            color: 'red',
            marginBottom: 0
          }}>{error}</p>}
        </div>
      ) : type == "select" ? (
        <div className="inputContainer">
          <p className="labelStyle">{label}</p>
          <Select
            prefix={prefix ? prefix : null}
            suffix={suffix ? suffix : null}
            prefixIcon={<InboxOutlined />}
            className="inputStyles"
            placeholder={placeholder}
          >
            options={[{ value: 'lucy', label: 'Lucy' }]}
          </Select>
        </div>
      ) : (
        <div className="inputContainer">
          <p className="labelStyle">{label}</p>
          <Input
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            prefix={prefix ? prefix : null}
            suffix={suffix ? suffix : null}
            className="inputStyles"
          />
              {error && <p style={{
                textAlign: 'left',
                color: 'red',
                marginBottom: 0
              }}>{error}</p>}
        </div>
      )}
    </>
  );
};

export default InputField;
