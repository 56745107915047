import actions from './actions';

const { BEGIN, ERROR, GET_USER_MANAGMENT } = actions;

const initialState = {
    users: [],
    loading: false,
    error: null,
    total: 0,
    userMember: null,
};

const userReducer = (state = initialState, action) => {
    const { type, data, err, total } = action;
    switch (type) {
        case BEGIN:
            return {
                ...state,
                loading: true,
            };
        case ERROR:
            return {
                ...state,
                loading: false,
                error: err,
            };
        case GET_USER_MANAGMENT:
            return {
                ...state,
                loading: false,
                users: data,
                total,
            };
        default:
            return state;
    }
};

export default userReducer;
