const actions = {
    ERROR: 'ERROR',
    BEGIN: 'BEGIN',
    SET_DASHBOARD: 'SET_DASHBOARD',

    begin: () => {
        return {
            type: actions.BEGIN,
        };
    },

    error: (err) => {
        return {
            type: actions.ERROR,
            err,
        };
    },

    setDashboard: (data) => {
        return {
            type: actions.SET_DASHBOARD,
            data,
        };
    },
};

export default actions;
