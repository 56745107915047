import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Spin, Button, Table, Modal } from "antd";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { FieldTimeOutlined } from "@ant-design/icons";
import moment from "moment";
import { Url } from "../../../utils/apiUrl";
import { getApiWithAuth } from "../../../utils/api";
import "./AnalyticsIUsers.scss";

const AnalyticsUsers = () => {
  ChartJS.register(...registerables);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [userData, setUserData] = useState([]);
  const [gender, setGender] = useState("all");
  const [startDate, setStartDate] = useState(new Date());
  const [selectedUser, setSelectedUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("date");
  const [formattedDate, setFormattedDate] = useState(
    moment(startDate).format("YYYY-MM-DD")
  );
  const [userDuration, setUserDuration] = useState([]);
  const [isLoading, setIsLoading] = useState({
    fetchClubLoader: false,
    chartLoader: false,
  });

  const columns =
    gender == "all" || gender == "All"
      ? [
          {
            title: "Student ID",
            dataIndex: "student_id",
            key: "student_id",
            width: "20%",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: "20%",
          },
          {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
          },
          {
            title: "User Type",
            dataIndex: "userType",
            key: "userType",
          },
          {
            title: "Spend Time",
            dataIndex: "spendTime",
            key: "spendTime",
            width: "13%",
          },
          {
            title: "Gender",
            dataIndex: "userGender",
            key: "userGender",
            width: "13%",
          },
        ]
      : [
          {
            title: "Student ID",
            dataIndex: "student_id",
            key: "student_id",
            width: "30%",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: "25%",
          },
          {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
          },
          {
            title: "User Type",
            dataIndex: "userType",
            key: "userType",
          },
          {
            title: "Spend Time",
            dataIndex: "spendTime",
            key: "spendTime",
            width: "13%",
          },
        ];

  const tableDataSource = (data) => {
    let dataArray = [];
    data?.map((item) => {
      dataArray.push({
        key: item._id,
        student_id: item._id,
        email: item.email,
        dob: item.dateofbirth,
        userType: item.usertype,
        userGender: item.gender,
        spendTime: (
          <div
            onClick={() => {
              setShowModal(true);
              setSelectedUser(item);
            }}
          >
            <FieldTimeOutlined />
          </div>
        ),
      });
    });

    setUserData(dataArray);
  };

  const getAnalyticUsersYear = async () => {
    setIsLoadingTable(true);
    const date = startDate?.getFullYear();
    const response = await getApiWithAuth(`${Url.analyticUsersYear}${date}`);
    if (response.success) {
      tableDataSource(response.data);
    }
    setIsLoadingTable(false);
  };

  const getAnalyticUsersYearnGender = async () => {
    setIsLoadingTable(true);
    const date = startDate?.getFullYear();
    const response = await getApiWithAuth(
      `${Url.analyticUsersYear}${date}&gender=${gender}`
    );
    if (response.success) {
      tableDataSource(response.data.response);
    }
    setIsLoadingTable(false);
  };

  const getAnalyticUsersGender = async () => {
    setIsLoadingTable(true);
    const response = await getApiWithAuth(
      `${Url.analyticUsersGender}${gender}`
    );
    if (response.success) {
      tableDataSource(response.data.response);
    }
    setIsLoadingTable(false);
  };

  const handleChange = (event) => {
    setUserData([]);
    setGender(event.target.value);
  };

  const handleMonthData = (data) => {
    let dataArray = [];
    data.map((item) => {
      let counter = 0;
      item.count.map((item) => {
        return (counter += item.spendMinute);
      });
      dataArray.push({
        _id: item._id,
        spendTime: counter,
      });
    });
    setUserDuration(dataArray);
  };

  const handleYearData = (data) => {
    let dataArray = [];
    let counter = 0;
    data.map((item) => {
      item.days.map((dayTime) => {
        dayTime.count.map((timeCount) => {
          return (counter += timeCount.spendMinute);
        });
      });

      dataArray.push({
        _id: item.month,
        spendTime: counter,
      });
    });

    setUserDuration(dataArray);
  };

  const handleButtonFilterClick = (type, format) => {
    setSelectedButton(type);
    setFormattedDate(moment(startDate).format(format));
  };

  const handleButtonGoClick = () => {
    if (Object.keys(selectedUser).length > 0) {
      getDurationData();
    }
  };

  const getDurationData = async () => {
    setIsLoading({ ...isLoading, chartLoader: true });
    const response = await getApiWithAuth(
      `${Url.userApplicationDuration}${selectedUser._id}&${selectedButton}=${formattedDate}`
    );
    if (response.success) {
      if (selectedButton == "month") handleMonthData(response.data);
      else handleYearData(response.data);
    }

    setIsLoading({ ...isLoading, chartLoader: false });
  };

  const onClickFilter = () => {
    if (gender.toLowerCase() != "all" && startDate != null)
      getAnalyticUsersYearnGender();
    else if (gender.toLowerCase() != "all" && startDate == null)
      getAnalyticUsersGender();
    else if (startDate != null && gender.toLowerCase() == "all") {
      getAnalyticUsersYear();
    }
  };

  const onClickReset = () => {
    setGender("all");
    setStartDate(null);
  };

  const showLineChartFilters = () => {
    return (
      <div>
        <div className="modalTopContainer">
          <div className="userTimeButtonContainer">
            <Button
              style={{
                marginRight: "15px",
                backgroundColor: "#5ce1e6",
                border: " 1px solid #5ce1e6",
              }}
              type="primary"
              onClick={() => handleButtonFilterClick("month", "YYYY-MM")}
              className={
                selectedButton === "month"
                  ? "btnStyle selected-button"
                  : "btnStyle"
              }
            >
              Month
            </Button>
            <Button
              style={{
                marginRight: "15px",
                backgroundColor: "#5ce1e6",
                border: " 1px solid #5ce1e6",
              }}
              type="primary"
              onClick={() => handleButtonFilterClick("year", "YYYY")}
              className={
                selectedButton === "year"
                  ? "btnStyle selected-button"
                  : "btnStyle"
              }
            >
              Year
            </Button>
          </div>
          <div style={{ width: "30%", marginLeft: "15px" }}>
            {selectedButton === "date" ? (
              <DatePicker
                maxDate={new Date()}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy-MM-dd"
              />
            ) : selectedButton === "month" ? (
              <DatePicker
                maxDate={new Date()}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy-MM"
                showMonthYearPicker
              />
            ) : (
              <DatePicker
                maxDate={new Date()}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy"
                showYearPicker
              />
            )}
          </div>

          <Button
            type="primary"
            className="btnStyle"
            style={{
              width: "25%",
              backgroundColor: "#5ce1e6",
              border: " 1px solid #5ce1e6",
            }}
            onClick={handleButtonGoClick}
          >
            Go
          </Button>
        </div>

        {showDurationChart()}
      </div>
    );
  };

  const showDurationChart = () => {
    let labelArr;
    if (selectedButton == "year") {
      labelArr = userDuration.map((item) => {
        return moment()
          .month(item._id - 1)
          .format("MMM");
      });
    } else {
      labelArr = userDuration.map((item) => {
        return item._id;
      });
    }

    let duration = userDuration.map((item) => {
      return item.spendTime;
    });

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
      },
    };

    const data = {
      labels: labelArr,
      datasets: [
        {
          label: "Duration",
          data: duration,
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };

    return (
      <>
        {userDuration.length > 0 ? (
          <div className="chartDiv">
            <Line data={data} options={options} />
          </div>
        ) : isLoading.chartLoader ? (
          <div className="noDataStyleAnalytics">
            <Spin tip="Loading" size="large" style={{ width: "100%" }} />
          </div>
        ) : (
          <div>
            <p className="noDataStyleAnalytics">NO DATA</p>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (selectedButton === "date") {
      setFormattedDate(moment(startDate).format("YYYY-MM-DD"));
    } else if (selectedButton === "month") {
      setFormattedDate(moment(startDate).format("YYYY-MM"));
    } else {
      setFormattedDate(moment(startDate).format("YYYY"));
    }
  }, [startDate]);

  useEffect(() => {
    setUserDuration([]);
  }, [showModal]);

  return (
    <>
      <div className="homeContainer">
        <div className="homeInnerContainer">
          <div className="homePoweredText">
            <span className="poweredText">
              POWERED BY NEVERLEFT DIGITAL LTD.
            </span>
          </div>
          <div className="analyticsHomePageOuterContainer">
            <div className="homepageHeading userHeading">
              <h1>USERS</h1>
            </div>

            <div className="analyticButtonSelector">
              <div>
                <DatePicker
                  maxDate={new Date()}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  dateFormat="yyyy"
                  showYearPicker
                  className="react-datepickerStyle"
                  placeholderText={"Select Year"}
                />
              </div>

              <div className="yearSelector">
                <select
                  name="cars"
                  id="cars"
                  value={gender}
                  onChange={handleChange}
                  className="selectOption"
                >
                  <option value="All">All</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="btnStyle">
                <Button
                  type="primary"
                  className="btnStyle"
                  style={{
                    marginLeft: "18%",
                    backgroundColor: "#5ce1e6",
                    border: " 1px solid #5ce1e6",
                  }}
                  onClick={onClickFilter}
                >
                  Apply Filters
                </Button>
              </div>

              <div className="btnStyle">
                <Button
                  type="primary"
                  className="btnStyle"
                  style={{
                    marginLeft: "27%",
                    backgroundColor: "#5ce1e6",
                    border: " 1px solid #5ce1e6",
                  }}
                  onClick={onClickReset}
                >
                  Reset Filters
                </Button>
              </div>

              <div className="analyticTotalDiv">{`Total: ${userData.length}`}</div>
            </div>
            <div className="analyticUserTable">
              {isLoadingTable ? (
                <div className="spinDiv">
                  <Spin size="large" />
                </div>
              ) : (
                <Table
                  dataSource={userData}
                  columns={columns}
                  pagination={false}
                  scroll={{
                    y: 300,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={800}
        title={selectedUser.email}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
      >
        {showLineChartFilters()}
      </Modal>
    </>
  );
};

export default AnalyticsUsers;
