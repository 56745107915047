import actions from './actions';

const { SET_DASHBOARD, BEGIN, ERROR } = actions;

const initialState = {
    analytics: null,
    loading: false,
    error: null,
};

const dashboardReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case BEGIN:
            return {
                ...state,
                loading: true,
            };
        case ERROR:
            return {
                ...state,
                loading: false,
                error: err,
            };
        case SET_DASHBOARD:
            return {
                ...state,
                analytics: data,
                loading: false,
            };
        default:
            return state;
    }
};

export default dashboardReducer;
