import React from 'react'
import PageHeader from '../../components/page-header/PageHeader'

export default function Comming() {
    return (
        <div>
            <PageHeader label={'Page Under Development'} />
        </div>
    )
}
