import React, { useState } from "react";
import { Modal, Button } from "antd";
import "./NLModal.scss";

const NLModal = ({ bodyText, handleModel, showInput, children, handleConfirm, showIcon, icon, width, showBodyText, showHeading, cancelButtonText, confirmButtonText, heading, showConfirmBUtton, showCancelBUtton, isOpen }) => {
  return (
    <>
      <div >
        <Modal
          open={isOpen}
          onOk={handleConfirm}
          className="customModal"
          onCancel={handleModel}
          footer={null}
          width={width}
        >
          <div>
            {showIcon && <div style={{ textAlign: 'center' }} className="centeredLogo">{icon}</div>
            }
            <div className="textBelowSVG">
              {showHeading && <h1 >{heading}</h1>}
            </div>
          </div>

          <div className="pausedPermanently">
            {showBodyText && <p >{bodyText} </p>}
          </div>

          {showInput && children}


          <div className="confirmBtn">
            {showConfirmBUtton && <button style={{ cursor: 'pointer' }} onClick={handleConfirm}>{confirmButtonText}</button>}
          </div>
          <div className="cancelBtn">
            {showCancelBUtton && <button style={{ cursor: 'pointer' }} onClick={handleModel}>{cancelButtonText}</button>}
          </div>
        </Modal>
      </div>


    </>
  );
};

export default NLModal;
